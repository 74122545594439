/* --------------------------------------------------------------------------
 __  __           _____ _____ _______      ________            _____ _______
|  \/  |   /\    / ____/ ____|_   _\ \    / /  ____|     /\   |  __ \__   __|
| \  / |  /  \  | (___| (___   | |  \ \  / /| |__       /  \  | |__) | | |
| |\/| | / /\ \  \___ \\___ \  | |   \ \/ / |  __|     / /\ \ |  _  /  | |
| |  | |/ ____ \ ____) |___) |_| |_   \  /  | |____   / ____ \| | \ \  | |
|_|  |_/_/    \_\_____/_____/|_____|   \/   |______| /_/    \_\_|  \_\ |_|
-------------------------------------------------------------------------- */
/* Slider */
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=cb25880d-d8c1-45a6-b0e2-243247dc4059&fontids=5341516,5347279,5431370,5431396");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-size: 12px;
  line-height: 38px;
  color: #444;
  height: 38px;
  user-select: none;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background-color: #F8f8f8;
  color: #BBB;
  text-align: center;
  font: 0/0 a;
  *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #BBB;
  border-bottom: none;
}

.selectric-focus .selectric {
  border-color: #aaaaaa;
}

.selectric-hover .selectric {
  border-color: #c4c4c4;
}

.selectric-hover .selectric .button {
  color: #a2a2a2;
}

.selectric-hover .selectric .button:after {
  border-top-color: #a2a2a2;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: #c4c4c4;
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #c4c4c4;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 10px;
  color: #666;
  cursor: pointer;
}

.selectric-items li.selected {
  background: #E0E0E0;
  color: #444;
}

.selectric-items li.highlighted {
  background: #D0D0D0;
  color: #444;
}

.selectric-items li:hover {
  background: #D5D5D5;
  color: #444;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

html, body, div, span, object, iframe,
h1, h2, h3, .block h1, h4, h5, h6, p, .block-text-content, .contacts .contact-position, .contacts .contact-info, .search-vcard, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/**
 * Sub-Portals Colors.
 */
/**
 * Styling Colors.
 */
/**
 * Fonts.
 */
/**
 * Layers.
 */
/**
 * OSX Font Fix Mixins.
 */
/**
 * Used if the background-image is set via HTML in the "style" attribute.
 */
.grid {
  font-size: 0;
}

.grid-one-third,
.grid-two-thirds,
.grid-one-half,
.grid-one-sixth,
.grid-two-sixth,
.grid-three-sixth,
.grid-four-sixth {
  display: inline-block;
  vertical-align: top;
  font-size: 0;
}

@media all and (max-width: 768px) {
  .grid-one-third.grid-tp-one-whole,
  .grid-two-thirds.grid-tp-one-whole,
  .grid-one-half.grid-tp-one-whole,
  .grid-one-sixth.grid-tp-one-whole,
  .grid-two-sixth.grid-tp-one-whole,
  .grid-three-sixth.grid-tp-one-whole,
  .grid-four-sixth.grid-tp-one-whole {
    width: 100%;
  }
}

@media all and (max-width: 768px) {
  .grid-one-third.grid-tb-one-third,
  .grid-two-thirds.grid-tb-one-third,
  .grid-one-half.grid-tb-one-third,
  .grid-one-sixth.grid-tb-one-third,
  .grid-two-sixth.grid-tb-one-third,
  .grid-three-sixth.grid-tb-one-third,
  .grid-four-sixth.grid-tb-one-third {
    width: 33.3333%;
  }
}

@media all and (max-width: 667px) {
  .grid-one-third.grid-sl-one-whole,
  .grid-two-thirds.grid-sl-one-whole,
  .grid-one-half.grid-sl-one-whole,
  .grid-one-sixth.grid-sl-one-whole,
  .grid-two-sixth.grid-sl-one-whole,
  .grid-three-sixth.grid-sl-one-whole,
  .grid-four-sixth.grid-sl-one-whole {
    width: 100%;
  }
}

.grid-three-sixth,
.grid-one-half {
  width: 50%;
}

.grid-one-sixth {
  width: 16.6666%;
}

.grid-one-third,
.grid-two-sixth {
  width: 33.3333%;
}

.grid-two-thirds,
.grid-four-sixth {
  width: 66.6666%;
}

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers.
You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com
*/
@font-face {
  font-family: "Neue Haas Unica W05 Thin";
  src: url("/bundles/app/default/fonts/NeueHaasUnica/5341516/4da0387f-97b2-4951-a3d2-49871bbf43a9.woff2") format("woff2"), url("/bundles/app/default/fonts/NeueHaasUnica/5341516/127780c2-02f9-4482-835c-91f436f86364.woff") format("woff");
}

@font-face {
  font-family: "Neue Haas Unica W05 Light";
  src: url("/bundles/app/default/fonts/NeueHaasUnica/5347279/ee3363d8-a0e1-4473-9ec2-33fdca5a0e1f.woff2") format("woff2"), url("/bundles/app/default/fonts/NeueHaasUnica/5347279/2a3feb86-a0c8-4980-a428-ae3436c2d768.woff") format("woff");
}

@font-face {
  font-family: "Neue Haas Unica W05 Medium";
  src: url("/bundles/app/default/fonts/NeueHaasUnica/5431370/acc5cc87-2fbc-4338-acdc-cffde27a7dad.woff2") format("woff2"), url("/bundles/app/default/fonts/NeueHaasUnica/5431370/25247354-7bd0-484a-a8ca-594aaa8acf66.woff") format("woff");
}

@font-face {
  font-family: "Neue Haas Unica W05 Regular";
  src: url("/bundles/app/default/fonts/NeueHaasUnica/5431396/29c7a38f-5f84-48fc-9617-0c0c4e904bb9.woff2") format("woff2"), url("/bundles/app/default/fonts/NeueHaasUnica/5431396/4fa4dad4-ff58-4d4b-a8be-490f27776e1b.woff") format("woff");
}

/**
 * Font families.
 */
.main-nav-title {
  font-family: "Neue Haas Unica W05 Thin";
  font-weight: normal;
  font-style: normal;
}

strong, h2, h3, .block h1, .error-wrapper p, .error-wrapper .block-text-content, .error-wrapper .contacts .contact-position, .contacts .error-wrapper .contact-position, .error-wrapper .contacts .contact-info, .contacts .error-wrapper .contact-info, .error-wrapper .search-vcard, .styled-select, .styled-search, .btn, .btn-white, .block-downloads-item, .block-downloads-title, .content-sidebar-info, :root .content-sidebar-btn, .footer-nav-item a, .page-bar-btn-text, .main-nav-sub-title, .search-contact-info.mail, .download-vcard a, .startpage-article-emergency .startpage-article-content, .startpage-article-emergency .startpage-article-emergency-title, .startpage-article-button, .block h2, .contacts .contact-name, .contacts .contact-info.mail, .search-vcard, .internal-links-container .internal-link-more, .overview-more, .overview-list-title, .overview-article-description strong, .event-filter .btn, .event-filter-add span, .event-submit-form-container h1, .event-submit-label, .companies-search-icon, .show-more, .show-less, .entry-form-label {
  font-family: "Neue Haas Unica W05 Medium";
  font-weight: normal;
  font-style: normal;
}

*, p, .block-text-content, .contacts .contact-position, .contacts .contact-info, .search-vcard,
li,
ol li:before, h1, h4, .main-nav-sub-title, .startpage-article-tag, .overview-article-tag, .event-overview-location, .error-headline, input,
textarea, .styled-select .selectric .label, .styled-select .selectric-scroll li, .block-quote-text p, .block-quote-text .block-text-content, .block-quote-text .search-vcard, .gallery-status, .pagination-link, .page-footer h3, .page-footer a, .breadcrumb-item, .main-nav-sub-item a, .search-contact-info, .startpage-article-content .dialect, .header .sub-title, .header .date, .block h1, .overview-article-description, .event-overview-date, .event-submit-description, .form-label, .main-nav-title, .entry-form-title, .entry-form-note p, .entry-form-note .block-text-content, .entry-form-note .search-vcard {
  font-family: "Neue Haas Unica W05 Regular";
  font-weight: normal;
  font-style: normal;
}

/**
 * Font Default definition.
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

strong {
  font-size: inherit;
  line-height: inherit;
}

em {
  font-style: italic;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

p, .block-text-content, .contacts .contact-position, .contacts .contact-info, .search-vcard,
li,
ol li:before {
  font-size: 16px;
  line-height: 24px;
}

@media all and (max-width: 667px) {
  p, .block-text-content, .contacts .contact-position, .contacts .contact-info, .search-vcard,
  li,
  ol li:before {
    font-size: 14px;
    line-height: 20px;
  }
}

a {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.is-lehre-main p a, .is-lehre-main .block-text-content a, .is-lehre-main .contacts .contact-position a, .contacts .is-lehre-main .contact-position a, .is-lehre-main .contacts .contact-info a, .contacts .is-lehre-main .contact-info a, .is-lehre-main .search-vcard a, .is-lehre-main
li a {
  color: #3CB6CE;
}

:root .is-lehre p a, :root .is-lehre .block-text-content a, :root .is-lehre .contacts .contact-position a, .contacts :root .is-lehre .contact-position a, :root .is-lehre .contacts .contact-info a, .contacts :root .is-lehre .contact-info a, :root .is-lehre .search-vcard a, :root .is-lehre
li a {
  color: #3CB6CE;
}

:root .is-facebook p a, :root .is-facebook .block-text-content a, :root .is-facebook .contacts .contact-position a, .contacts :root .is-facebook .contact-position a, :root .is-facebook .contacts .contact-info a, .contacts :root .is-facebook .contact-info a, :root .is-facebook .search-vcard a, :root .is-facebook
li a {
  color: #3C5B9B;
}

:root .is-youtube p a, :root .is-youtube .block-text-content a, :root .is-youtube .contacts .contact-position a, .contacts :root .is-youtube .contact-position a, :root .is-youtube .contacts .contact-info a, .contacts :root .is-youtube .contact-info a, :root .is-youtube .search-vcard a, :root .is-youtube
li a {
  color: #FE3432;
}

:root .is-twitter p a, :root .is-twitter .block-text-content a, :root .is-twitter .contacts .contact-position a, .contacts :root .is-twitter .contact-position a, :root .is-twitter .contacts .contact-info a, .contacts :root .is-twitter .contact-info a, :root .is-twitter .search-vcard a, :root .is-twitter
li a {
  color: #2DAAE1;
}

:root .is-instagram p a, :root .is-instagram .block-text-content a, :root .is-instagram .contacts .contact-position a, .contacts :root .is-instagram .contact-position a, :root .is-instagram .contacts .contact-info a, .contacts :root .is-instagram .contact-info a, :root .is-instagram .search-vcard a, :root .is-instagram
li a {
  color: #6A453B;
}

a:hover {
  text-decoration: underline;
}

h1 {
  font-size: 52px;
  line-height: 56px;
}

.block h1 {
  margin-bottom: 10px;
}

@media all and (max-width: 667px) {
  h1 {
    font-size: 28px;
    line-height: 34px;
  }
}

h2 {
  font-size: 24px;
  line-height: 30px;
}

@media all and (max-width: 667px) {
  h2 {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
  }
}

h3, .block h1 {
  font-size: 26px;
  line-height: 32px;
}

@media all and (max-width: 667px) {
  h3, .block h1 {
    font-size: 22px;
    line-height: 28px;
  }
}

h4 {
  font-size: 48px;
  line-height: 60px;
}

/**
 * Font default styles.
 */
.main-nav-sub-title, .startpage-article-tag, .overview-article-tag, .event-overview-location {
  font-size: 18px;
  line-height: 28px;
}

@media all and (max-width: 667px) {
  .main-nav-sub-title, .startpage-article-tag, .overview-article-tag, .event-overview-location {
    font-size: 16px;
    line-height: 24px;
  }
}

@font-face {
  font-family: 'lustenau';
  src: url("/bundles/app/default/fonts/icomoon/fonts/lustenau.eot?e78wwi");
  src: url("/bundles/app/default/fonts/icomoon/fonts/lustenau.eot?e78wwi#iefix") format("embedded-opentype"), url("/bundles/app/default/fonts/icomoon/fonts/lustenau.ttf?e78wwi") format("truetype"), url("/bundles/app/default/fonts/icomoon/fonts/lustenau.woff?e78wwi") format("woff"), url("/bundles/app/default/fonts/icomoon/fonts/lustenau.svg?e78wwi#lustenau") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lustenau' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-nach-oben:before {
  content: "\e922";
}

.icon-check-bold:before, .checkbox input:checked + .form-label::after {
  content: "\e921";
}

.icon-vcard:before {
  content: "\e920";
  color: #006f49;
}

.icon-ambulance:before {
  content: "\e91f";
}

.icon-world-hand:before {
  content: "\e907";
}

.icon-books-library:before {
  content: "\e912";
}

.icon-webcam:before {
  content: "\e913";
}

.icon-buildings:before {
  content: "\e918";
}

.icon-find-job:before {
  content: "\e91c";
}

.icon-newspaper:before {
  content: "\e91d";
}

.icon-map-pin-1:before {
  content: "\e91e";
}

.icon-phone:before {
  content: "\e908";
}

.icon-clock:before {
  content: "\e90d";
}

.icon-pdf:before {
  content: "\e91a";
}

.icon-doc:before {
  content: "\e91b";
}

.icon-file-new-1:before {
  content: "\e900";
}

.icon-add:before {
  content: "\e901";
}

.icon-arrow-down:before {
  content: "\e902";
}

.icon-arrow-left:before {
  content: "\e903";
}

.icon-arrow-right:before {
  content: "\e904";
}

.icon-arrow-up:before {
  content: "\e905";
}

.icon-arrow-up2:before {
  content: "\e906";
}

.icon-calendar-2:before {
  content: "\e909";
}

.icon-close:before, .is-open .search-btn [class^="icon-"]:before, .is-open .search-btn [class*=" icon-"]:before {
  content: "\e90a";
}

.icon-dropdown:before, .styled-select:before, .styled-select .selectric .button:after {
  content: "\e90b";
}

.icon-facebook:before {
  content: "\e90c";
}

.icon-globe-3:before {
  content: "\e90e";
}

.icon-home:before {
  content: "\e90f";
}

.icon-instagram:before {
  content: "\e910";
}

.icon-location-pin-2:before {
  content: "\e911";
}

.icon-phone-5:before {
  content: "\e914";
}

.icon-rectangle:before {
  content: "\e915";
}

.icon-search:before, .companies-search-icon:before {
  content: "\e916";
}

.icon-twitter:before {
  content: "\e917";
}

.icon-youtube:before {
  content: "\e919";
}

/**
 * Navigation Icon
 */
.nav-icon {
  position: relative;
  width: 27px;
  height: 25px;
  transform: rotate(0deg);
  transition: .3s ease-in-out;
  cursor: pointer;
}

.nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #000000;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .20s ease-in-out;
  border-radius: 1px;
}

.nav-icon span:nth-child(1) {
  top: 0;
}

.nav-icon span:nth-child(2),
.nav-icon span:nth-child(3) {
  top: 10px;
}

.nav-icon span:nth-child(4) {
  top: 20px;
}

.is-open-mobile-nav .nav-icon span:nth-child(1), .nav-icon.is-open span:nth-child(1) {
  top: 10px;
  width: 0;
  left: 50%;
}

.is-open-mobile-nav .nav-icon span:nth-child(2), .nav-icon.is-open span:nth-child(2) {
  transform: rotate(45deg);
}

.is-open-mobile-nav .nav-icon span:nth-child(3), .nav-icon.is-open span:nth-child(3) {
  transform: rotate(-45deg);
}

.is-open-mobile-nav .nav-icon span:nth-child(4), .nav-icon.is-open span:nth-child(4) {
  top: 10px;
  width: 0;
  left: 50%;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #F2F2F2;
  min-height: 100%;
}

.content {
  padding-bottom: 60px;
}

.content.no-padding-bottom {
  padding-bottom: 0;
}

@media all and (max-width: 1024px) {
  .content {
    padding-bottom: 40px;
  }
}

@media all and (max-width: 667px) {
  .content {
    padding-bottom: 30px;
  }
}

.content-small, .content-sidebar {
  width: 100%;
  max-width: 940px;
  margin: 0 auto 50px;
}

@media all and (max-width: 1024px) {
  .content-small, .content-sidebar {
    padding: 0 54px;
  }
}

@media all and (max-width: 667px) {
  .content-small, .content-sidebar {
    margin: 0 0 30px;
    padding: 0 15px;
  }
}

.content-break {
  margin-top: 80px;
  border-top: 1px solid #E8E8E8;
  padding-bottom: 50px;
}

@media all and (max-width: 667px) {
  .content-break {
    display: none;
  }
}

.content-break.no-margin-top {
  margin-top: 0;
}

@media all and (max-width: 667px) {
  .page-content {
    margin-top: 20px;
  }
}

.page-content.companies .content-small, .page-content.companies .content-sidebar {
  margin-bottom: 20px;
}

.page-content.companies .content-small h1, .page-content.companies .content-sidebar h1 {
  margin-bottom: 20px;
}

.no-scroll {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.content, .breadcrumb, .content-max-width {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
}

@media all and (max-width: 1280px) {
  .content, .breadcrumb, .content-max-width {
    width: auto;
    margin: 0 54px;
  }
}

@media all and (max-width: 667px) {
  .content, .breadcrumb, .content-max-width {
    margin: 0 15px;
  }
}

.contacts.is-slider h1 {
  padding-left: 50px;
  padding-right: 50px;
}

@media all and (max-width: 667px) {
  .contacts.is-slider h1 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.contacts .slick-dots {
  position: absolute;
  bottom: 25px;
  text-align: center;
  pointer-events: none;
  width: 100%;
}

.contacts .slick-dots li {
  display: inline-block;
  vertical-align: middle;
  pointer-events: all;
}

@media all and (max-width: 667px) {
  .contacts .slick-dots {
    bottom: 16px;
  }
}

.contacts .slick-dot {
  transition: all 0.3s ease;
  border: 2px solid transparent;
  cursor: pointer;
  margin: 0 10px;
}

@media all and (max-width: 667px) {
  .contacts .slick-dot {
    border: 1px solid transparent;
    margin: 0 5px;
  }
}

.contacts .slick-dot-inner {
  background-color: #ffffff;
  width: 10px;
  height: 10px;
  transition: all 0.3s ease;
}

@media all and (max-width: 667px) {
  .contacts .slick-dot-inner {
    width: 8px;
    height: 8px;
  }
}

.contacts .slick-active .slick-dot {
  border: 2px solid #ffffff;
}

@media all and (max-width: 667px) {
  .contacts .slick-active .slick-dot {
    border: 1px solid #ffffff;
  }
}

.contacts .slick-active .slick-dot-inner {
  background-color: transparent;
}

.center-text {
  text-align: center;
}

.default-section {
  position: relative;
  margin: 80px auto 20px;
}

@media all and (max-width: 768px) {
  .default-section {
    margin-top: 50px;
  }
}

@media all and (max-width: 667px) {
  .default-section {
    margin-top: 30px;
  }
}

.grid-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  font-size: 0;
}

@media all and (max-width: 667px) {
  .error-content {
    padding: 0;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.error-wrapper {
  position: relative;
  display: block;
  margin: 130px 0;
  text-align: center;
}

.error-wrapper p, .error-wrapper .block-text-content, .error-wrapper .contacts .contact-position, .contacts .error-wrapper .contact-position, .error-wrapper .contacts .contact-info, .contacts .error-wrapper .contact-info, .error-wrapper .search-vcard {
  margin: 20px 0;
  font-size: 36px;
  line-height: 48px;
}

@media all and (max-width: 667px) {
  .error-wrapper {
    margin: auto;
  }
  .error-wrapper p, .error-wrapper .block-text-content, .error-wrapper .contacts .contact-position, .contacts .error-wrapper .contact-position, .error-wrapper .contacts .contact-info, .contacts .error-wrapper .contact-info, .error-wrapper .search-vcard {
    font-size: 24px;
    line-height: 32px;
  }
}

.error-headline {
  font-size: 186px;
  line-height: 190px;
  color: #000000;
  letter-spacing: 0;
}

.is-hidden {
  display: none;
}

.is-bold {
  font-weight: bold;
}

.ratio-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.ratio-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.search-result {
  font-size: 0;
  margin-top: 70px;
}

.search-result h2 {
  margin-bottom: 50px;
}

.search-item {
  min-height: 83px;
}

.search-filter-item {
  display: inline-block;
  vertical-align: top;
  width: 33.3333%;
  padding: 0 10px;
}

.search-filter-item:last-child {
  padding-right: 0;
}

.search-filter-item.large {
  width: 66.6666%;
  padding-left: 0;
}

.search-filter-item.whole-width {
  width: 100%;
  padding: 0;
}

@media all and (max-width: 667px) {
  .search-filter-item {
    width: 100%;
    padding: 10px 0 0;
  }
  .search-filter-item.large {
    width: 100%;
  }
}

.image-description-text {
  position: relative;
  display: block;
  padding-top: 8px;
  font-size: 14px;
  line-height: 20px;
}

input,
textarea,
select {
  outline: none;
}

input,
textarea {
  width: 100%;
  padding: 15px 20px;
  background-color: #ffffff;
  border: 1px solid #E8E8E8;
  font-size: 16px;
  line-height: 24px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  margin: 0;
}

@media all and (max-width: 667px) {
  input,
  textarea {
    font-size: 16px;
  }
}

textarea {
  max-width: 100%;
  min-height: 160px;
  resize: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.styled-select {
  position: relative;
  width: 100%;
  border: 1px solid #E8E8E8;
  background-color: #ffffff;
  cursor: pointer;
}

.styled-select:before {
  font-family: 'lustenau';
  position: absolute;
  top: -12px;
  right: 8px;
  font-size: 8px;
  line-height: 58px;
  color: #000000;
  cursor: pointer;
  pointer-events: none;
}

@media all and (max-width: 667px) {
  .styled-select:before {
    line-height: 40px;
  }
}

.styled-select select {
  background: transparent;
  width: 100%;
  padding: 0 60px;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  font-size: 20px;
  line-height: 58px;
  cursor: pointer;
}

@media all and (max-width: 667px) {
  .styled-select select {
    font-size: 16px;
    line-height: 40px;
    padding: 0 20px;
  }
}

.styled-select .selectric {
  border: none;
}

.styled-select .selectric .label {
  color: #000000;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  padding: 0 20px;
  margin: auto;
  border-radius: 0;
  height: auto;
}

@media all and (max-width: 667px) {
  .styled-select .selectric .label {
    font-size: 14px;
    line-height: 20px;
  }
}

.styled-select .selectric .button {
  height: 100%;
}

.styled-select .selectric .button:after {
  font-family: 'lustenau';
  display: block;
  position: absolute;
  top: -12px;
  right: 8px;
  font-size: 8px;
  line-height: 58px;
  color: #000000;
  cursor: pointer;
  pointer-events: none;
}

@media all and (max-width: 667px) {
  .styled-select .selectric .button:after {
    line-height: 44px;
  }
}

.styled-select .selectric-scroll ul {
  margin-left: 0;
}

.styled-select .selectric-scroll li {
  color: #000000;
  border: none;
  padding: 20px 20px;
  list-style: none;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  margin: auto;
}

.styled-select .selectric-scroll li:before {
  display: none;
}

.styled-select .selectric-scroll li:hover {
  color: #ffffff;
}

.is-lehre-main .styled-select .selectric-scroll li:hover {
  background-color: #3CB6CE;
}

:root .is-lehre .styled-select .selectric-scroll li:hover {
  background-color: #3CB6CE;
}

:root .is-facebook .styled-select .selectric-scroll li:hover {
  background-color: #3C5B9B;
}

:root .is-youtube .styled-select .selectric-scroll li:hover {
  background-color: #FE3432;
}

:root .is-twitter .styled-select .selectric-scroll li:hover {
  background-color: #2DAAE1;
}

:root .is-instagram .styled-select .selectric-scroll li:hover {
  background-color: #6A453B;
}

@media all and (max-width: 667px) {
  .styled-select .selectric-scroll li {
    padding: 10px 10px;
  }
}

@media all and (max-width: 667px) {
  .styled-select .selectric .label {
    line-height: 40px;
  }
  .styled-select .selectric .button:after {
    line-height: 40px;
  }
}

.selectric .button:after {
  border: none;
  bottom: auto;
}

.styled-search {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #ffffff;
  cursor: pointer;
}

:root .required-empty {
  border-color: red;
}

.input-item {
  padding-top: 30px;
}

.submit-button {
  margin-top: 30px;
  display: block;
  width: auto;
  cursor: pointer;
}

.indicates-required {
  text-align: right;
  margin-top: 12px;
}

.news-letter-wrapper {
  padding-left: 120px;
}

@media all and (max-width: 1024px) {
  .news-letter-wrapper {
    padding-left: 0;
  }
}

.news-letter-headline {
  text-align: center;
}

.form-recaptcha-wrapper {
  display: block;
  margin-bottom: 20px;
}

.event-form-recaptcha-wrapper {
  display: block;
  padding: 30px 0 0 20px;
}

.form-google-recaptcha {
  position: relative;
  display: inline-block;
}

.form-google-recaptcha.error {
  border: 1px solid red;
}

.btn {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  padding: 14px 22px 12px;
  border: 2px solid;
  transition: all 0.3s ease;
  cursor: pointer;
}

.is-lehre-main .btn {
  background-color: #3CB6CE;
}

:root .is-lehre .btn {
  background-color: #3CB6CE;
}

:root .is-facebook .btn {
  background-color: #3C5B9B;
}

:root .is-youtube .btn {
  background-color: #FE3432;
}

:root .is-twitter .btn {
  background-color: #2DAAE1;
}

:root .is-instagram .btn {
  background-color: #6A453B;
}

.is-lehre-main .btn {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-lehre .btn {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-facebook .btn {
  color: #3C5B9B;
  color: #ffffff;
}

:root .is-youtube .btn {
  color: #FE3432;
  color: #ffffff;
}

:root .is-twitter .btn {
  color: #2DAAE1;
  color: #ffffff;
}

:root .is-instagram .btn {
  color: #6A453B;
  color: #ffffff;
}

.is-lehre-main .btn {
  border-color: #3CB6CE;
}

:root .is-lehre .btn {
  border-color: #3CB6CE;
}

:root .is-facebook .btn {
  border-color: #3C5B9B;
}

:root .is-youtube .btn {
  border-color: #FE3432;
}

:root .is-twitter .btn {
  border-color: #2DAAE1;
}

:root .is-instagram .btn {
  border-color: #6A453B;
}

.btn.no-touchevents:hover {
  text-decoration: none;
}

.is-lehre-main .btn.no-touchevents:hover {
  color: #3CB6CE;
}

:root .is-lehre .btn.no-touchevents:hover {
  color: #3CB6CE;
}

:root .is-facebook .btn.no-touchevents:hover {
  color: #3C5B9B;
}

:root .is-youtube .btn.no-touchevents:hover {
  color: #FE3432;
}

:root .is-twitter .btn.no-touchevents:hover {
  color: #2DAAE1;
}

:root .is-instagram .btn.no-touchevents:hover {
  color: #6A453B;
}

.is-lehre-main .btn.no-touchevents:hover {
  border-color: #3CB6CE;
}

:root .is-lehre .btn.no-touchevents:hover {
  border-color: #3CB6CE;
}

:root .is-facebook .btn.no-touchevents:hover {
  border-color: #3C5B9B;
}

:root .is-youtube .btn.no-touchevents:hover {
  border-color: #FE3432;
}

:root .is-twitter .btn.no-touchevents:hover {
  border-color: #2DAAE1;
}

:root .is-instagram .btn.no-touchevents:hover {
  border-color: #6A453B;
}

.is-lehre-main .btn.no-touchevents:hover {
  background-color: #3CB6CE;
  background-color: rgba(255, 255, 0, 0);
}

:root .is-lehre .btn.no-touchevents:hover {
  background-color: #3CB6CE;
  background-color: rgba(255, 255, 0, 0);
}

:root .is-facebook .btn.no-touchevents:hover {
  background-color: #3C5B9B;
  background-color: rgba(255, 255, 0, 0);
}

:root .is-youtube .btn.no-touchevents:hover {
  background-color: #FE3432;
  background-color: rgba(255, 255, 0, 0);
}

:root .is-twitter .btn.no-touchevents:hover {
  background-color: #2DAAE1;
  background-color: rgba(255, 255, 0, 0);
}

:root .is-instagram .btn.no-touchevents:hover {
  background-color: #6A453B;
  background-color: rgba(255, 255, 0, 0);
}

.is-lehre-main .btn.no-touchevents:hover.has-hover-bg-white {
  background-color: #3CB6CE;
  background-color: #ffffff;
}

:root .is-lehre .btn.no-touchevents:hover.has-hover-bg-white {
  background-color: #3CB6CE;
  background-color: #ffffff;
}

:root .is-facebook .btn.no-touchevents:hover.has-hover-bg-white {
  background-color: #3C5B9B;
  background-color: #ffffff;
}

:root .is-youtube .btn.no-touchevents:hover.has-hover-bg-white {
  background-color: #FE3432;
  background-color: #ffffff;
}

:root .is-twitter .btn.no-touchevents:hover.has-hover-bg-white {
  background-color: #2DAAE1;
  background-color: #ffffff;
}

:root .is-instagram .btn.no-touchevents:hover.has-hover-bg-white {
  background-color: #6A453B;
  background-color: #ffffff;
}

.is-lehre-main .btn.no-touchevents:hover.icon-add {
  color: #3CB6CE;
}

:root .is-lehre .btn.no-touchevents:hover.icon-add {
  color: #3CB6CE;
}

:root .is-facebook .btn.no-touchevents:hover.icon-add {
  color: #3C5B9B;
}

:root .is-youtube .btn.no-touchevents:hover.icon-add {
  color: #FE3432;
}

:root .is-twitter .btn.no-touchevents:hover.icon-add {
  color: #2DAAE1;
}

:root .is-instagram .btn.no-touchevents:hover.icon-add {
  color: #6A453B;
}

.btn-white-wrapper {
  margin-top: 60px;
  position: relative;
  display: block;
}

.btn-white {
  color: #000000;
  cursor: pointer;
  position: relative;
  display: block;
  border: 1px solid #E8E8E8;
  background-color: #ffffff;
  margin: 0 auto;
  max-width: 225px;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  padding: 12px 22px;
  text-align: center;
}

.btn-white:hover {
  text-decoration: underline;
}

.btn-white.remove-button {
  display: none;
}

.scroll-up {
  display: block;
  position: fixed;
  bottom: -50px;
  right: 0;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  cursor: pointer;
  opacity: 0;
  padding: 22px 18px;
  transition: 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.scroll-up.show-button {
  bottom: 30px;
  z-index: 10;
  opacity: 1;
}

@media all and (max-width: 530px) {
  .scroll-up {
    display: none;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-top: 14px;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.block-images {
  font-size: 0;
}

.block-images-item {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding-right: 10px;
  padding-top: 20px;
}

.block-images-item:nth-child(2n+0) {
  padding-left: 10px;
  padding-right: 0;
}

.block-images-item:first-child {
  padding-top: 0;
}

.block-images-item:nth-child(2) {
  padding-top: 0;
}

.block-images-item img {
  display: block;
  width: 100%;
}

.is-single-image .block-images-item {
  width: 100%;
  padding-right: 0;
}

@media all and (max-width: 667px) {
  .block-images-item {
    display: block;
    width: 100%;
    padding: 0 0 20px 0;
  }
  .block-images-item:nth-child(2n+0) {
    padding-left: 0;
  }
  .block-images-item:last-child {
    padding-bottom: 0;
  }
}

.block-quote {
  padding: 0 50px;
}

@media all and (max-width: 667px) {
  .block-quote {
    padding: 0 20px;
  }
}

.block-quote-text p, .block-quote-text .block-text-content, .block-quote-text .contacts .contact-position, .contacts .block-quote-text .contact-position, .block-quote-text .contacts .contact-info, .contacts .block-quote-text .contact-info, .block-quote-text .search-vcard {
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
}

.is-lehre-main .block-quote-text p, .is-lehre-main .block-quote-text .block-text-content, .is-lehre-main .block-quote-text .contacts .contact-position, .contacts .is-lehre-main .block-quote-text .contact-position, .is-lehre-main .block-quote-text .contacts .contact-info, .contacts .is-lehre-main .block-quote-text .contact-info, .is-lehre-main .block-quote-text .search-vcard {
  color: #3CB6CE;
}

:root .is-lehre .block-quote-text p, :root .is-lehre .block-quote-text .block-text-content, :root .is-lehre .block-quote-text .contacts .contact-position, .contacts :root .is-lehre .block-quote-text .contact-position, :root .is-lehre .block-quote-text .contacts .contact-info, .contacts :root .is-lehre .block-quote-text .contact-info, :root .is-lehre .block-quote-text .search-vcard {
  color: #3CB6CE;
}

:root .is-facebook .block-quote-text p, :root .is-facebook .block-quote-text .block-text-content, :root .is-facebook .block-quote-text .contacts .contact-position, .contacts :root .is-facebook .block-quote-text .contact-position, :root .is-facebook .block-quote-text .contacts .contact-info, .contacts :root .is-facebook .block-quote-text .contact-info, :root .is-facebook .block-quote-text .search-vcard {
  color: #3C5B9B;
}

:root .is-youtube .block-quote-text p, :root .is-youtube .block-quote-text .block-text-content, :root .is-youtube .block-quote-text .contacts .contact-position, .contacts :root .is-youtube .block-quote-text .contact-position, :root .is-youtube .block-quote-text .contacts .contact-info, .contacts :root .is-youtube .block-quote-text .contact-info, :root .is-youtube .block-quote-text .search-vcard {
  color: #FE3432;
}

:root .is-twitter .block-quote-text p, :root .is-twitter .block-quote-text .block-text-content, :root .is-twitter .block-quote-text .contacts .contact-position, .contacts :root .is-twitter .block-quote-text .contact-position, :root .is-twitter .block-quote-text .contacts .contact-info, .contacts :root .is-twitter .block-quote-text .contact-info, :root .is-twitter .block-quote-text .search-vcard {
  color: #2DAAE1;
}

:root .is-instagram .block-quote-text p, :root .is-instagram .block-quote-text .block-text-content, :root .is-instagram .block-quote-text .contacts .contact-position, .contacts :root .is-instagram .block-quote-text .contact-position, :root .is-instagram .block-quote-text .contacts .contact-info, .contacts :root .is-instagram .block-quote-text .contact-info, :root .is-instagram .block-quote-text .search-vcard {
  color: #6A453B;
}

@media all and (max-width: 667px) {
  .block-quote-text p, .block-quote-text .block-text-content, .block-quote-text .contacts .contact-position, .contacts .block-quote-text .contact-position, .block-quote-text .contacts .contact-info, .contacts .block-quote-text .contact-info, .block-quote-text .search-vcard {
    font-size: 24px;
    line-height: 36px;
  }
}

.block-quote-author {
  text-align: right;
  text-transform: uppercase;
}

.block-quote-author p, .block-quote-author .block-text-content, .block-quote-author .contacts .contact-position, .contacts .block-quote-author .contact-position, .block-quote-author .contacts .contact-info, .contacts .block-quote-author .contact-info, .block-quote-author .search-vcard {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

@media all and (max-width: 667px) {
  .block-quote-author p, .block-quote-author .block-text-content, .block-quote-author .contacts .contact-position, .contacts .block-quote-author .contact-position, .block-quote-author .contacts .contact-info, .contacts .block-quote-author .contact-info, .block-quote-author .search-vcard {
    font-size: 14px;
    line-height: 24px;
  }
}

.block-downloads {
  margin-top: 14px;
  font-size: 0;
}

.block-downloads-item {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding-right: 20px;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.is-lehre-main .block-downloads-item {
  color: #3CB6CE;
}

:root .is-lehre .block-downloads-item {
  color: #3CB6CE;
}

:root .is-facebook .block-downloads-item {
  color: #3C5B9B;
}

:root .is-youtube .block-downloads-item {
  color: #FE3432;
}

:root .is-twitter .block-downloads-item {
  color: #2DAAE1;
}

:root .is-instagram .block-downloads-item {
  color: #6A453B;
}

.block-downloads-item:hover {
  text-decoration: none;
}

.one-column .block-downloads-item {
  width: 100%;
}

.one-column .block-downloads-item:nth-child(-n+2) {
  margin-top: 20px;
}

.block-downloads-item [class^="icon-"], .block-downloads-item [class*=" icon-"] {
  display: inline-block;
  vertical-align: top;
  width: 30px;
  font-size: 20px;
}

.block-downloads-item:nth-child(-n+2) {
  margin-top: 0;
}

@media all and (max-width: 667px) {
  .block-downloads-item {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
  .block-downloads-item:nth-child(-n+2) {
    margin-top: 20px;
  }
  .block-downloads-item:first-child {
    margin-top: 0;
  }
}

.block-downloads-title {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 35px);
  text-decoration: underline;
}

.block-text-with-image {
  position: relative;
}

.block-text-with-image:after {
  content: "";
  display: table;
  clear: both;
}

.block-text-with-image .block-text-with-image-container {
  float: right;
  padding: 0 0 0 20px;
  margin-bottom: 10px;
  max-width: 420px;
  z-index: 2;
  background-color: #F2F2F2;
  position: relative;
}

@media all and (max-width: 667px) {
  .block-text-with-image .block-text-with-image-container {
    width: 100%;
    float: none;
    padding: 0;
    margin-bottom: 20px;
    max-width: none;
  }
}

.block-text-with-image img {
  display: block;
  width: 100%;
}

.block-text-with-image.has-left-image .block-text-with-image-container {
  float: left;
  padding: 0 20px 0 0;
  margin-bottom: 10px;
}

@media all and (max-width: 667px) {
  .block-text-with-image.has-left-image .block-text-with-image-container {
    float: none;
    padding: 0;
    margin-bottom: 20px;
  }
}

.gallery {
  position: relative;
}

.gallery .slick-arrow {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 0;
  width: 42px;
  height: 42px;
  margin-top: -21px;
  border: none;
  text-align: center;
  cursor: pointer;
}

.is-lehre-main .gallery .slick-arrow {
  background-color: #3CB6CE;
}

:root .is-lehre .gallery .slick-arrow {
  background-color: #3CB6CE;
}

:root .is-facebook .gallery .slick-arrow {
  background-color: #3C5B9B;
}

:root .is-youtube .gallery .slick-arrow {
  background-color: #FE3432;
}

:root .is-twitter .gallery .slick-arrow {
  background-color: #2DAAE1;
}

:root .is-instagram .gallery .slick-arrow {
  background-color: #6A453B;
}

.gallery .slick-arrow [class^="icon-"], .gallery .slick-arrow [class*=" icon-"] {
  color: #ffffff;
  font-size: 12px;
  line-height: 42px;
}

.gallery .slick-next {
  left: auto;
  right: 0;
}

.gallery.has-image-description .slick-arrow {
  margin-top: -51px;
}

.gallery.has-image-description .slick-slide {
  padding-bottom: 60px;
  position: relative;
}

.gallery img {
  width: 100%;
}

.gallery-image-description-wrapper {
  position: absolute;
}

.gallery-status {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.has-image-description .gallery-status {
  bottom: 80px;
}

.pagination {
  text-align: center;
}

.pagination a {
  text-decoration: none;
}

.pagination a:hover {
  text-decoration: none;
}

.pagination a [class^="icon-"], .pagination a [class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  line-height: 24px;
  padding-right: 30px;
  padding-left: 30px;
}

.is-lehre-main .pagination a [class^="icon-"], .is-lehre-main .pagination a [class*=" icon-"] {
  color: #3CB6CE;
}

:root .is-lehre .pagination a [class^="icon-"], :root .is-lehre .pagination a [class*=" icon-"] {
  color: #3CB6CE;
}

:root .is-facebook .pagination a [class^="icon-"], :root .is-facebook .pagination a [class*=" icon-"] {
  color: #3C5B9B;
}

:root .is-youtube .pagination a [class^="icon-"], :root .is-youtube .pagination a [class*=" icon-"] {
  color: #FE3432;
}

:root .is-twitter .pagination a [class^="icon-"], :root .is-twitter .pagination a [class*=" icon-"] {
  color: #2DAAE1;
}

:root .is-instagram .pagination a [class^="icon-"], :root .is-instagram .pagination a [class*=" icon-"] {
  color: #6A453B;
}

@media all and (max-width: 768px) {
  .pagination a [class^="icon-"], .pagination a [class*=" icon-"] {
    padding-left: 15px;
    padding-right: 20px;
  }
}

@media all and (max-width: 667px) {
  .pagination a [class^="icon-"], .pagination a [class*=" icon-"] {
    font-size: 20px;
  }
}

.pagination-link {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  line-height: 32px;
  color: #4A4A4A;
}

@media all and (max-width: 768px) {
  .pagination-link {
    font-size: 24px;
    line-height: 32px;
  }
}

@media all and (max-width: 667px) {
  .pagination-link {
    display: none;
  }
}

.pagination-prev {
  margin-right: 15px;
}

.pagination-next {
  margin-left: 15px;
}

.pagination-separation-line {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 40px;
  background-color: #B0B0B0;
  margin: 0 30px;
}

.pagination-separation-line.hide {
  display: none;
}

@media all and (max-width: 768px) {
  .pagination-separation-line {
    display: none;
  }
}

.content-sidebar {
  float: right;
  width: 340px;
  padding-left: 20px;
  padding-right: 0;
}

@media all and (max-width: 768px) {
  .content-sidebar {
    float: none;
    width: auto;
    padding-left: 0;
  }
}

.has-content-sidebar {
  padding-right: 60px;
  width: calc(100% - 340px);
  margin-left: 0;
  margin-right: auto;
}

.has-content-sidebar .block-text-content {
  padding-right: 0;
}

@media all and (max-width: 1024px) {
  .has-content-sidebar {
    padding-right: 20px;
  }
}

@media all and (max-width: 768px) {
  .has-content-sidebar {
    width: 100%;
  }
}

.sidebar-wrapper {
  text-align: center;
}

.sidebar-wrapper-inner {
  display: inline-block;
  text-align: left;
  max-width: 100%;
}

.content-sidebar-inner {
  padding: 0;
}

.content-sidebar-item {
  padding: 30px;
  border-bottom: 1px solid #E8E8E8;
  font-size: 0;
}

.content-sidebar-item [class^="icon-"], .content-sidebar-item [class*=" icon-"] {
  display: inline-block;
  vertical-align: top;
  width: 45px;
  font-size: 20px;
}

.is-lehre-main .content-sidebar-item [class^="icon-"], .is-lehre-main .content-sidebar-item [class*=" icon-"] {
  color: #3CB6CE;
}

:root .is-lehre .content-sidebar-item [class^="icon-"], :root .is-lehre .content-sidebar-item [class*=" icon-"] {
  color: #3CB6CE;
}

:root .is-facebook .content-sidebar-item [class^="icon-"], :root .is-facebook .content-sidebar-item [class*=" icon-"] {
  color: #3C5B9B;
}

:root .is-youtube .content-sidebar-item [class^="icon-"], :root .is-youtube .content-sidebar-item [class*=" icon-"] {
  color: #FE3432;
}

:root .is-twitter .content-sidebar-item [class^="icon-"], :root .is-twitter .content-sidebar-item [class*=" icon-"] {
  color: #2DAAE1;
}

:root .is-instagram .content-sidebar-item [class^="icon-"], :root .is-instagram .content-sidebar-item [class*=" icon-"] {
  color: #6A453B;
}

@media all and (max-width: 1024px) {
  .content-sidebar-item {
    padding: 15px;
  }
}

.content-sidebar-info {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 45px);
  font-size: 20px;
  line-height: 26px;
}

@media all and (max-width: 1024px) {
  .content-sidebar-info {
    font-size: 16px;
    line-height: 20px;
  }
}

.content-sidebar-btn {
  display: block;
  padding: 12px;
  text-align: center;
}

.is-lehre-main .content-sidebar-btn {
  background-color: #3CB6CE;
}

:root .is-lehre .content-sidebar-btn {
  background-color: #3CB6CE;
}

:root .is-facebook .content-sidebar-btn {
  background-color: #3C5B9B;
}

:root .is-youtube .content-sidebar-btn {
  background-color: #FE3432;
}

:root .is-twitter .content-sidebar-btn {
  background-color: #2DAAE1;
}

:root .is-instagram .content-sidebar-btn {
  background-color: #6A453B;
}

:root .content-sidebar-btn {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;
}

.content-sidebar-btn [class^="icon-"], .content-sidebar-btn [class*=" icon-"] {
  padding-right: 10px;
}

.box {
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.has-copyright {
  display: block;
  position: relative;
}

.copyright {
  position: absolute;
  z-index: 20;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.copyright .has-left-image {
  right: 20px;
}

@media all and (max-width: 667px) {
  .copyright .has-left-image {
    right: 0;
  }
}

.copyright p, .copyright .block-text-content, .copyright .contacts .contact-position, .contacts .copyright .contact-position, .copyright .contacts .contact-info, .contacts .copyright .contact-info, .copyright .search-vcard {
  color: #000000;
  font-size: 14px;
  line-height: 14px;
  padding: 5px 5px;
}

.page-header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 60px 0 90px;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 152px;
  background: #ffffff;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.page-header:not(.headroom--top)::before {
  opacity: 1;
}

.page-header.headroom--unpinned {
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
}

@media all and (max-width: 1200px) {
  .page-header {
    padding: 0 24px;
  }
}

@media all and (max-width: 1024px) {
  .page-header {
    height: 72px;
    padding: 0 28px 0 18px;
  }
}

.page-header-nav {
  position: relative;
}

.page-header-logo {
  display: block;
  position: relative;
  max-width: 261px;
  padding-bottom: 9px;
}

.page-header-logo img {
  display: block;
  max-height: 78px;
  max-width: 261px;
  width: auto;
}

@media all and (max-width: 1024px) {
  .page-header-logo.is-hidden {
    display: block;
    opacity: 0;
    pointer-events: none;
  }
}

@media all and (max-width: 1024px) {
  .page-header-logo {
    padding-bottom: 3px;
  }
  .page-header-logo img {
    max-height: 55px;
  }
}

.page-header-logo[data-webspace="musikschule"] {
  padding-bottom: 22px;
}

.page-header-logo[data-webspace="musikschule"] img {
  max-height: 104px;
}

@media all and (max-width: 1024px) {
  .page-header-logo[data-webspace="musikschule"] {
    padding-bottom: 5px;
  }
  .page-header-logo[data-webspace="musikschule"] img {
    max-height: 62px;
  }
}

.page-header-logo[data-webspace="bibliothek"] img,
.page-header-logo[data-webspace="marketing"] img,
.page-header-logo[data-webspace="kindergarten"] img,
.page-header-logo[data-webspace="lehre"] img {
  max-height: 110px;
}

@media all and (max-width: 1024px) {
  .page-header-logo[data-webspace="bibliothek"],
  .page-header-logo[data-webspace="marketing"],
  .page-header-logo[data-webspace="kindergarten"],
  .page-header-logo[data-webspace="lehre"] {
    padding-bottom: 5px;
  }
  .page-header-logo[data-webspace="bibliothek"] img,
  .page-header-logo[data-webspace="marketing"] img,
  .page-header-logo[data-webspace="kindergarten"] img,
  .page-header-logo[data-webspace="lehre"] img {
    max-height: 62px;
  }
}

.page-header-logo[data-webspace="kindergarten"] img {
  max-width: none;
}

.page-header-logo[data-webspace="galerie-hollenstein"] {
  max-width: 428px;
}

.page-header-logo[data-webspace="galerie-hollenstein"] img {
  max-height: none;
  max-width: none;
  width: 428px;
}

@media all and (max-width: 667px) {
  .page-header-logo[data-webspace="galerie-hollenstein"] img {
    width: 200px;
  }
}

.page-header-placeholder {
  height: 152px;
}

@media all and (max-width: 1024px) {
  .page-header-placeholder {
    height: 72px;
  }
}

.page-footer {
  position: relative;
  background-color: #ffffff;
  padding: 40px 0;
  border-bottom: 3px solid #F2F3F1;
  color: #0D0E0C;
}

.page-footer h3, .page-footer .block h1, .block .page-footer h1 {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
}

.page-footer h3 span, .page-footer .block h1 span, .block .page-footer h1 span {
  display: block;
}

.is-lehre-main .page-footer h3 span, .is-lehre-main .page-footer .block h1 span, .block .is-lehre-main .page-footer h1 span {
  color: #3CB6CE;
}

:root .is-lehre .page-footer h3 span, :root .is-lehre .page-footer .block h1 span, .block :root .is-lehre .page-footer h1 span {
  color: #3CB6CE;
}

:root .is-facebook .page-footer h3 span, :root .is-facebook .page-footer .block h1 span, .block :root .is-facebook .page-footer h1 span {
  color: #3C5B9B;
}

:root .is-youtube .page-footer h3 span, :root .is-youtube .page-footer .block h1 span, .block :root .is-youtube .page-footer h1 span {
  color: #FE3432;
}

:root .is-twitter .page-footer h3 span, :root .is-twitter .page-footer .block h1 span, .block :root .is-twitter .page-footer h1 span {
  color: #2DAAE1;
}

:root .is-instagram .page-footer h3 span, :root .is-instagram .page-footer .block h1 span, .block :root .is-instagram .page-footer h1 span {
  color: #6A453B;
}

@media all and (max-width: 1024px) {
  .page-footer h3, .page-footer .block h1, .block .page-footer h1 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }
}

.page-footer a {
  display: inline-block;
  color: #0D0E0C;
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
}

@media all and (max-width: 1024px) {
  .page-footer a {
    font-size: 14px;
  }
}

.page-footer p, .page-footer .block-text-content, .page-footer .contacts .contact-position, .contacts .page-footer .contact-position, .page-footer .contacts .contact-info, .contacts .page-footer .contact-info, .page-footer .search-vcard {
  margin-bottom: 24px;
}

.page-footer p:last-child, .page-footer .block-text-content:last-child, .page-footer .contacts .contact-position:last-child, .contacts .page-footer .contact-position:last-child, .page-footer .contacts .contact-info:last-child, .contacts .page-footer .contact-info:last-child, .page-footer .search-vcard:last-child {
  margin-bottom: 0;
}

@media all and (max-width: 1024px) {
  .page-footer p, .page-footer .block-text-content, .page-footer .contacts .contact-position, .contacts .page-footer .contact-position, .page-footer .contacts .contact-info, .contacts .page-footer .contact-info, .page-footer .search-vcard {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

@media all and (max-width: 667px) {
  .page-footer {
    padding: 40px 0 0 0;
  }
  .page-footer .content {
    margin: 0 16px;
    padding-bottom: 0;
  }
}

@media all and (max-width: 1024px) {
  .page-footer {
    border: none;
    padding: 24px 0 32px;
  }
}

.page-footer-inner {
  display: flex;
  gap: 64px;
  padding-bottom: 0;
}

.page-footer--with-padding .page-footer-inner {
  padding-inline: 10px;
}

@media all and (max-width: 1024px) {
  .page-footer-inner {
    display: block;
  }
}

@media all and (max-width: 667px) {
  .page-footer--with-padding .page-footer-inner {
    padding-inline: 0;
  }
}

.page-footer-item {
  flex: 0 0 278px;
}

@media all and (max-width: 1024px) {
  .page-footer-item {
    margin-bottom: 32px;
  }
}

.page-footer-logo {
  flex: 1;
  align-self: center;
  text-align: right;
}

.page-footer-logo img {
  width: 100%;
  max-width: 220px;
}

@media all and (max-width: 1024px) {
  .page-footer-logo {
    text-align: left;
    margin-bottom: 0;
  }
}

.footer {
  padding: 20px 0;
  background-color: #ffffff;
}

.footer .grid {
  vertical-align: middle;
}

.footer p, .footer .block-text-content, .footer .contacts .contact-position, .contacts .footer .contact-position, .footer .contacts .contact-info, .contacts .footer .contact-info, .footer .search-vcard, .footer a {
  color: #0D0E0C !important;
  font-size: 16px;
  line-height: 18px;
}

.footer .content {
  padding-bottom: 0;
}

.footer .content:after {
  content: "";
  display: table;
  clear: both;
}

@media all and (max-width: 667px) {
  .footer .content {
    margin: 0 16px;
  }
}

@media all and (max-width: 1024px) {
  .footer {
    padding: 0 0 20px 0;
  }
}

.footer--with-padding .footer-bar {
  padding-inline: 10px;
}

@media all and (max-width: 667px) {
  .footer--with-padding .footer-bar {
    padding-inline: 0;
  }
}

.footer-nav-item {
  display: inline-block;
  vertical-align: middle;
  padding-left: 40px;
}

.footer-nav-item a {
  display: block;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
}

@media all and (max-width: 1100px) {
  .footer-nav-item a {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

@media all and (max-width: 1100px) {
  .footer-nav-item {
    display: block;
    padding: 0;
  }
}

.footer-copyright {
  float: left;
}

@media all and (max-width: 1100px) {
  .footer-copyright {
    float: none;
    margin-top: 32px;
  }
  .footer-copyright p, .footer-copyright .block-text-content, .footer-copyright .contacts .contact-position, .contacts .footer-copyright .contact-position, .footer-copyright .contacts .contact-info, .contacts .footer-copyright .contact-info, .footer-copyright .search-vcard {
    font-size: 14px;
    line-height: 24px;
  }
}

.footer-item {
  float: right;
}

@media all and (max-width: 1100px) {
  .footer-item {
    float: none;
  }
}

.page-bar {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 90;
}

.page-bar.is-shortcut-bar {
  right: 0;
  left: auto;
}

@media all and (max-height: 800px) {
  .page-bar.is-shortcut-bar {
    top: 160px;
    transform: translate(0, 0);
  }
}

@media all and (max-height: 800px) and (max-width: 1024px) {
  .page-bar.is-shortcut-bar {
    top: 120px;
  }
}

@media all and (max-width: 667px) {
  .page-bar {
    display: none;
  }
}

.page-bar-inner {
  width: 50px;
  overflow: hidden;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
}

.page-bar-btn {
  text-align: center;
  border-bottom: 1px solid #E8E8E8;
  padding: 6px 0;
  background-color: #ffffff;
}

.page-bar-btn a {
  color: #000000;
  text-decoration: none;
}

.page-bar-btn:last-child {
  border-bottom: none;
}

.page-bar-btn [class^="icon-"], .page-bar-btn [class*=" icon-"] {
  font-size: 20px;
}

.page-bar-btn-text {
  display: block;
  margin-top: 5px;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.page-bar-social-btn {
  text-align: center;
}

.page-bar-social-btn [class^="icon-"], .page-bar-social-btn [class*=" icon-"] {
  font-size: 20px;
  line-height: 48px;
  color: #ffffff;
}

.page-bar-social-btn .icon-facebook,
.page-bar-social-btn .icon-instagram {
  font-size: 24px;
}

.page-bar-social-link {
  display: block;
  text-decoration: none;
}

.is-facebook .page-bar-social-link {
  background-color: #3C5B9B;
}

.is-youtube .page-bar-social-link {
  background-color: #FE3432;
}

.is-twitter .page-bar-social-link {
  background-color: #2DAAE1;
}

.is-instagram .page-bar-social-link {
  background-color: #6A453B;
}

.page-bar-social-link:hover {
  text-decoration: none;
}

.page-bar-btn-image {
  display: inline-block;
}

.page-bar-btn-image img {
  display: block;
  max-width: 100%;
  width: 30px;
  height: 30px;
}

@media all and (max-width: 667px) {
  .page-bar-btn-image img {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
}

.mobile-quicklinks {
  margin-bottom: 20px;
}

.mobile-quicklinks .page-bar {
  display: none;
}

@media all and (max-width: 667px) {
  .mobile-quicklinks .page-bar {
    position: relative;
    top: auto;
    transform: translate(0, 0);
    display: block;
  }
  .mobile-quicklinks .page-bar-inner {
    width: 100%;
  }
  .mobile-quicklinks .page-bar-btn {
    text-align: left;
    padding: 12px 20px;
  }
  .mobile-quicklinks .page-bar-btn a {
    display: flex;
  }
  .mobile-quicklinks .page-bar-btn i {
    position: absolute;
    display: flex;
    align-self: center;
    font-size: 10px;
    right: 25px;
  }
  .mobile-quicklinks .page-bar-btn-text {
    font-size: 12px;
    display: flex;
    align-self: center;
  }
}

.breadcrumb {
  padding: 24px 0 70px 0;
}

.breadcrumb ul {
  list-style: none;
}

.breadcrumb a {
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  color: #000000;
  text-decoration: none;
  letter-spacing: 1px;
}

.is-lehre-main .breadcrumb a {
  color: #3CB6CE;
  color: #000000;
}

:root .is-lehre .breadcrumb a {
  color: #3CB6CE;
  color: #000000;
}

:root .is-facebook .breadcrumb a {
  color: #3C5B9B;
  color: #000000;
}

:root .is-youtube .breadcrumb a {
  color: #FE3432;
  color: #000000;
}

:root .is-twitter .breadcrumb a {
  color: #2DAAE1;
  color: #000000;
}

:root .is-instagram .breadcrumb a {
  color: #6A453B;
  color: #000000;
}

.breadcrumb a:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 1px;
  margin: -2px 0 0 3px;
  background-color: #000000;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

@media all and (max-width: 667px) {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb-item {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.is-lehre-main .breadcrumb-item {
  color: #3CB6CE;
}

:root .is-lehre .breadcrumb-item {
  color: #3CB6CE;
}

:root .is-facebook .breadcrumb-item {
  color: #3C5B9B;
}

:root .is-youtube .breadcrumb-item {
  color: #FE3432;
}

:root .is-twitter .breadcrumb-item {
  color: #2DAAE1;
}

:root .is-instagram .breadcrumb-item {
  color: #6A453B;
}

.breadcrumb-item.is-home {
  font-size: 20px;
}

.breadcrumb-item.is-home a:after {
  margin-top: -3px;
}

.main-nav {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  margin: 0 50px 0 0;
  padding: 0;
}

@media all and (max-width: 1024px) {
  .main-nav {
    position: absolute;
    z-index: 100;
    top: 72px;
    left: 0;
    width: 100vw;
    background-color: #ffffff;
    margin-right: 0;
    transition: opacity 0.3s ease;
    overflow: hidden;
    opacity: 0;
    height: 0;
  }
  .is-open-mobile-nav .main-nav {
    display: block;
    position: fixed;
    opacity: 1;
    min-height: calc(100% - 72px);
    overflow-y: scroll;
  }
}

.main-nav-item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 60px;
  padding: 34px 0;
  overflow: hidden;
}

.main-nav-item a {
  text-decoration: none;
}

@media all and (max-width: 1024px) {
  .main-nav-item a {
    display: block;
    padding: 12px 24px;
  }
}

.main-nav-item:last-child {
  margin-right: 0;
}

.main-nav-item.is-search {
  cursor: pointer;
}

.main-nav-item.is-search [class^="icon-"], .main-nav-item.is-search [class*=" icon-"] {
  color: #000000;
  font-size: 24px;
}

.main-nav-item:hover {
  overflow: visible;
}

.main-nav-item:hover .main-nav-sub {
  height: auto;
  opacity: 1;
}

.is-lehre-main .main-nav-item:hover .main-nav-title {
  color: #3CB6CE;
}

:root .is-lehre .main-nav-item:hover .main-nav-title {
  color: #3CB6CE;
}

:root .is-facebook .main-nav-item:hover .main-nav-title {
  color: #3C5B9B;
}

:root .is-youtube .main-nav-item:hover .main-nav-title {
  color: #FE3432;
}

:root .is-twitter .main-nav-item:hover .main-nav-title {
  color: #2DAAE1;
}

:root .is-instagram .main-nav-item:hover .main-nav-title {
  color: #6A453B;
}

@media all and (max-width: 1340px) {
  .main-nav-item {
    margin-right: 35px;
  }
}

@media all and (max-width: 1024px) {
  .main-nav-item {
    display: block;
    padding: 0;
    margin-right: 0;
  }
  .main-nav-item:last-child {
    border-bottom: 0;
  }
  .main-nav-item:hover .main-nav-sub {
    height: 0;
    opacity: 0;
  }
}

.main-nav-title {
  display: block;
  font-size: 44px;
  line-height: 52px;
  color: #0D0E0C;
}

@media all and (max-width: 1200px) {
  .main-nav-title {
    font-size: 36px;
    line-height: 44px;
  }
}

@media all and (max-width: 1024px) {
  .main-nav-title {
    font-size: 44px;
    line-height: 52px;
  }
}

.main-nav-sub-title {
  display: block;
  font-size: 12px;
  line-height: 12px;
  color: #0D0E0C;
}

@media all and (max-width: 1200px) {
  .main-nav-sub-title {
    font-size: 10px;
    line-height: 10px;
  }
}

@media all and (max-width: 1024px) {
  .main-nav-sub-title {
    font-size: 12px;
    line-height: 14px;
  }
}

.main-nav-mobile {
  display: none;
  padding: 49px 0;
}

@media all and (max-width: 1024px) {
  .main-nav-mobile {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 25px;
  }
}

.main-nav-sub {
  position: absolute;
  top: 100%;
  left: -20px;
  z-index: 10;
  min-width: calc(100% + 40px);
  margin-top: 0;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.main-nav-sub-item {
  display: block;
  border-bottom: 1px solid #E8E8E8;
}

.main-nav-sub-item a {
  display: block;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  white-space: nowrap;
}

.is-lehre-main .main-nav-sub-item a {
  color: #3CB6CE;
  color: #2E2F2A;
}

:root .is-lehre .main-nav-sub-item a {
  color: #3CB6CE;
  color: #2E2F2A;
}

:root .is-facebook .main-nav-sub-item a {
  color: #3C5B9B;
  color: #2E2F2A;
}

:root .is-youtube .main-nav-sub-item a {
  color: #FE3432;
  color: #2E2F2A;
}

:root .is-twitter .main-nav-sub-item a {
  color: #2DAAE1;
  color: #2E2F2A;
}

:root .is-instagram .main-nav-sub-item a {
  color: #6A453B;
  color: #2E2F2A;
}

.no-touchevents .main-nav-sub-item:hover {
  background: #009FDA;
  border-bottom: 1px solid #009FDA;
}

.no-touchevents .main-nav-sub-item:hover a {
  color: #ffffff;
}

.main-nav-sub-item:first-of-type {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.is-lehre-main .main-nav-sub-item:hover {
  background-color: #3CB6CE;
}

:root .is-lehre .main-nav-sub-item:hover {
  background-color: #3CB6CE;
}

:root .is-facebook .main-nav-sub-item:hover {
  background-color: #3C5B9B;
}

:root .is-youtube .main-nav-sub-item:hover {
  background-color: #FE3432;
}

:root .is-twitter .main-nav-sub-item:hover {
  background-color: #2DAAE1;
}

:root .is-instagram .main-nav-sub-item:hover {
  background-color: #6A453B;
}

.is-lehre-main .main-nav-sub-item:hover {
  border-color: #3CB6CE;
}

:root .is-lehre .main-nav-sub-item:hover {
  border-color: #3CB6CE;
}

:root .is-facebook .main-nav-sub-item:hover {
  border-color: #3C5B9B;
}

:root .is-youtube .main-nav-sub-item:hover {
  border-color: #FE3432;
}

:root .is-twitter .main-nav-sub-item:hover {
  border-color: #2DAAE1;
}

:root .is-instagram .main-nav-sub-item:hover {
  border-color: #6A453B;
}

.search {
  display: inline-block;
  vertical-align: middle;
}

@media all and (max-width: 1024px) {
  .search {
    display: none;
  }
}

.search-btn {
  cursor: pointer;
  transition: all 0.3s ease;
}

.is-open .search-btn {
  font-size: 30px;
}

.search-btn-inner {
  display: block;
}

.is-open .search-btn-inner {
  display: none;
}

.search-btn-inner.is-close {
  display: none;
}

.search-btn-inner.is-close svg {
  width: 40px;
}

.is-open .search-btn-inner.is-close {
  display: block;
}

.search-field-container {
  position: absolute;
  left: 0;
  width: calc(100% - 40px);
  text-align: right;
  transition: opacity 0.3s ease;
  opacity: 0;
  height: 0;
}

.is-open .search-field-container {
  opacity: 1;
  top: 0;
  height: calc(100% + 2px);
  display: flex;
  align-items: center;
  z-index: 10;
}

@media all and (max-width: 1024px) {
  .search-field-container {
    height: auto;
    opacity: 1;
  }
}

.search-field-view {
  display: inline-block;
  overflow: hidden;
  transition: width 0.3s ease;
  width: 0;
}

.is-open .search-field-view {
  width: 100%;
  margin-top: -2px;
}

@media all and (max-width: 1024px) {
  .search-field-view {
    width: auto;
  }
}

.search-input {
  border: none;
  border-bottom: 1px solid #E8E8E8;
}

.search-mobile {
  display: none;
  padding: 0 24px 16px;
}

.search-mobile .search-input-container {
  display: flex;
  width: 100%;
  padding: 2px 8px;
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid #9C9E94;
  align-items: center;
}

.search-mobile .search-input {
  width: calc(100% - 40px);
  padding: 0 20px 0 4px;
  line-height: 24px;
  font-size: 14px;
  color: #5C5E55;
  background-color: transparent;
  border: none;
}

.search-mobile .search-btn {
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin: 0;
  border: none;
  background-color: transparent;
}

.search-mobile .search-btn svg {
  width: 20px;
  height: 20px;
}

.search-mobile .search-btn svg g[stroke='#0D0E0C'] {
  stroke: #9C9E94;
}

@media all and (max-width: 1024px) {
  .search-mobile {
    display: block;
  }
}

.search-contact-info {
  font-size: 18px;
  line-height: 28px;
  display: block;
}

.search-contact-info a {
  text-decoration: none;
}

.is-lehre-main .search-contact-info a {
  color: #3CB6CE;
}

:root .is-lehre .search-contact-info a {
  color: #3CB6CE;
}

:root .is-facebook .search-contact-info a {
  color: #3C5B9B;
}

:root .is-youtube .search-contact-info a {
  color: #FE3432;
}

:root .is-twitter .search-contact-info a {
  color: #2DAAE1;
}

:root .is-instagram .search-contact-info a {
  color: #6A453B;
}

.search-contact-info a:hover {
  text-decoration: underline;
}

.search-contact-info:root {
  margin-bottom: 0;
}

.search-contact-info.mail {
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
}

@media all and (max-width: 667px) {
  .search-contact-info {
    font-size: 14px;
    line-height: 24px;
  }
}

:root .download-vcard {
  flex: 0 0 140px;
}

.download-vcard a {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.is-lehre-main .download-vcard a {
  color: #3CB6CE;
}

:root .is-lehre .download-vcard a {
  color: #3CB6CE;
}

:root .is-facebook .download-vcard a {
  color: #3C5B9B;
}

:root .is-youtube .download-vcard a {
  color: #FE3432;
}

:root .is-twitter .download-vcard a {
  color: #2DAAE1;
}

:root .is-instagram .download-vcard a {
  color: #6A453B;
}

.download-vcard a:hover {
  text-decoration: underline;
}

.download-vcard i {
  position: absolute;
  top: 0;
  left: -35px;
}

@media all and (max-width: 768px) {
  :root .download-vcard {
    display: block;
    width: 100%;
    margin-left: 35px;
  }
}

.masonry-overview {
  padding-bottom: 160px;
}

.startpage-article-btn {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
}

.startpage-article-sizer,
.startpage-article {
  width: 33.3333%;
}

@media all and (max-width: 1024px) {
  .startpage-article-sizer,
  .startpage-article {
    width: 50%;
  }
}

@media all and (max-width: 667px) {
  .startpage-article-sizer,
  .startpage-article {
    width: 100%;
  }
}

.startpage-article {
  padding: 10px;
}

.startpage-article:nth-child(n + 18) {
  display: none;
}

.startpage-article:nth-child(n + 18).display-article {
  display: block;
  opacity: 0;
  transition: .2s ease-in;
}

.startpage-article:nth-child(n + 18).show-article {
  opacity: 1;
}

.startpage-article-inner {
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 #E8E8E8;
  border: 1px solid #E8E8E8;
  display: block;
  position: relative;
}

.is-facebook .startpage-article-inner {
  max-height: 280px;
  overflow: hidden;
}

.no-image .startpage-article-inner {
  height: 280px;
}

.startpage-article-image img {
  display: block;
  width: 100%;
}

.is-facebook .startpage-article-image img {
  min-height: 280px;
  object-fit: cover;
}

.no-objectfit .is-facebook .startpage-article-image img {
  height: auto;
}

.startpage-article-content {
  padding: 30px 40px 40px;
  text-decoration: none;
}

.startpage-article-content:hover {
  text-decoration: none;
}

.startpage-article-content p, .startpage-article-content .block-text-content, .startpage-article-content .contacts .contact-position, .contacts .startpage-article-content .contact-position, .startpage-article-content .contacts .contact-info, .contacts .startpage-article-content .contact-info, .startpage-article-content .search-vcard {
  margin-bottom: 20px;
  color: #4A4A4A;
}

.startpage-article-content a {
  text-decoration: none;
  color: #000000;
}

.startpage-article-content .dialect {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 20px;
}

.is-lehre-main .startpage-article-content .dialect {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-lehre .startpage-article-content .dialect {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-facebook .startpage-article-content .dialect {
  color: #3C5B9B;
  color: #ffffff;
}

:root .is-youtube .startpage-article-content .dialect {
  color: #FE3432;
  color: #ffffff;
}

:root .is-twitter .startpage-article-content .dialect {
  color: #2DAAE1;
  color: #ffffff;
}

:root .is-instagram .startpage-article-content .dialect {
  color: #6A453B;
  color: #ffffff;
}

.startpage-article-content .translation {
  margin-bottom: 0;
}

.startpage-article-event .startpage-article-content {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.startpage-article-emergency .startpage-article-content {
  background-color: #C4112F;
}

.startpage-article-facebook .startpage-article-content,
.startpage-article-youtube .startpage-article-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 50px;
}

@media all and (max-width: 667px) {
  .startpage-article-facebook .startpage-article-content,
  .startpage-article-youtube .startpage-article-content {
    padding-top: 20px;
  }
}

@media all and (max-width: 667px) {
  .startpage-article-content {
    padding: 20px;
  }
}

.startpage-article-headline {
  margin-bottom: 10px;
}

.startpage-article-event .startpage-article-headline,
.startpage-article-facebook .startpage-article-headline,
.startpage-article-youtube .startpage-article-headline {
  color: #ffffff;
  text-decoration: none;
}

.startpage-article-youtube .startpage-article-headline {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 72px;
}

.startpage-article-facebook .startpage-article-headline,
.startpage-article-youtube .startpage-article-headline {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
}

@media all and (max-width: 667px) {
  .startpage-article-facebook .startpage-article-headline,
  .startpage-article-youtube .startpage-article-headline {
    font-size: 18px;
    line-height: 26px;
  }
}

.startpage-article-tag {
  display: block;
  margin-bottom: 4px;
  line-height: 16px;
}

.is-lehre-main .startpage-article-tag {
  color: #3CB6CE;
}

.is-lehre-main .startpage-article-tag.is-white {
  color: #ffffff;
}

:root .is-lehre .startpage-article-tag {
  color: #3CB6CE;
}

:root .is-lehre .startpage-article-tag.is-white {
  color: #ffffff;
}

:root .is-facebook .startpage-article-tag {
  color: #3C5B9B;
}

:root .is-facebook .startpage-article-tag.is-white {
  color: #ffffff;
}

:root .is-youtube .startpage-article-tag {
  color: #FE3432;
}

:root .is-youtube .startpage-article-tag.is-white {
  color: #ffffff;
}

:root .is-twitter .startpage-article-tag {
  color: #2DAAE1;
}

:root .is-twitter .startpage-article-tag.is-white {
  color: #ffffff;
}

:root .is-instagram .startpage-article-tag {
  color: #6A453B;
}

:root .is-instagram .startpage-article-tag.is-white {
  color: #ffffff;
}

.startpage-article-event .startpage-article-tag,
.startpage-article-facebook .startpage-article-tag,
.startpage-article-youtube .startpage-article-tag {
  margin-bottom: 5px;
}

:root .startpage-article-event .startpage-article-tag, :root
.startpage-article-facebook .startpage-article-tag, :root
.startpage-article-youtube .startpage-article-tag {
  color: #ffffff;
}

.startpage-article-emergency .startpage-article-content {
  display: block;
  color: #ffffff;
  text-decoration: none;
  font-size: 0;
}

.startpage-article-emergency .startpage-article-emergency-title {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 63px);
  font-size: 24px;
  line-height: 36px;
}

.startpage-article-emergency [class^="icon-"], .startpage-article-emergency [class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
  width: 63px;
  font-size: 36px;
  line-height: 36px;
}

.startpage-article-youtube {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.startpage-article-youtube.is-loaded {
  opacity: 1;
}

.startpage-article-local-newspaper .startpage-article-content {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.startpage-article-local-newspaper .startpage-article-button {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
}

.startpage-article-button {
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
  padding: 9px 22px;
}

.startpage-article-button [class^="icon-"], .startpage-article-button [class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 19px;
  margin-right: 10px;
}

.mundart-article p, .mundart-article .block-text-content, .mundart-article .contacts .contact-position, .contacts .mundart-article .contact-position, .mundart-article .contacts .contact-info, .contacts .mundart-article .contact-info, .mundart-article .search-vcard {
  margin-bottom: 0;
}

.is-lehre-main .mundart-article p, .is-lehre-main .mundart-article .block-text-content, .is-lehre-main .mundart-article .contacts .contact-position, .contacts .is-lehre-main .mundart-article .contact-position, .is-lehre-main .mundart-article .contacts .contact-info, .contacts .is-lehre-main .mundart-article .contact-info, .is-lehre-main .mundart-article .search-vcard {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-lehre .mundart-article p, :root .is-lehre .mundart-article .block-text-content, :root .is-lehre .mundart-article .contacts .contact-position, .contacts :root .is-lehre .mundart-article .contact-position, :root .is-lehre .mundart-article .contacts .contact-info, .contacts :root .is-lehre .mundart-article .contact-info, :root .is-lehre .mundart-article .search-vcard {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-facebook .mundart-article p, :root .is-facebook .mundart-article .block-text-content, :root .is-facebook .mundart-article .contacts .contact-position, .contacts :root .is-facebook .mundart-article .contact-position, :root .is-facebook .mundart-article .contacts .contact-info, .contacts :root .is-facebook .mundart-article .contact-info, :root .is-facebook .mundart-article .search-vcard {
  color: #3C5B9B;
  color: #ffffff;
}

:root .is-youtube .mundart-article p, :root .is-youtube .mundart-article .block-text-content, :root .is-youtube .mundart-article .contacts .contact-position, .contacts :root .is-youtube .mundart-article .contact-position, :root .is-youtube .mundart-article .contacts .contact-info, .contacts :root .is-youtube .mundart-article .contact-info, :root .is-youtube .mundart-article .search-vcard {
  color: #FE3432;
  color: #ffffff;
}

:root .is-twitter .mundart-article p, :root .is-twitter .mundart-article .block-text-content, :root .is-twitter .mundart-article .contacts .contact-position, .contacts :root .is-twitter .mundart-article .contact-position, :root .is-twitter .mundart-article .contacts .contact-info, .contacts :root .is-twitter .mundart-article .contact-info, :root .is-twitter .mundart-article .search-vcard {
  color: #2DAAE1;
  color: #ffffff;
}

:root .is-instagram .mundart-article p, :root .is-instagram .mundart-article .block-text-content, :root .is-instagram .mundart-article .contacts .contact-position, .contacts :root .is-instagram .mundart-article .contact-position, :root .is-instagram .mundart-article .contacts .contact-info, .contacts :root .is-instagram .mundart-article .contact-info, :root .is-instagram .mundart-article .search-vcard {
  color: #6A453B;
  color: #ffffff;
}

.is-lehre-main .mundart-article .startpage-article-tag {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-lehre .mundart-article .startpage-article-tag {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-facebook .mundart-article .startpage-article-tag {
  color: #3C5B9B;
  color: #ffffff;
}

:root .is-youtube .mundart-article .startpage-article-tag {
  color: #FE3432;
  color: #ffffff;
}

:root .is-twitter .mundart-article .startpage-article-tag {
  color: #2DAAE1;
  color: #ffffff;
}

:root .is-instagram .mundart-article .startpage-article-tag {
  color: #6A453B;
  color: #ffffff;
}

.mundart-article .startpage-article-content {
  padding: 30px 40px;
  background-color: #B19357;
}

@media all and (max-width: 667px) {
  .mundart-article .startpage-article-content {
    padding: 30px 20px;
  }
}

.startpage-header {
  position: relative;
  margin-bottom: 60px;
}

.startpage-header.has-quicklinks {
  margin-bottom: 0;
}

.startpage-header .slick-dots {
  display: flex;
  column-gap: 12px;
  width: 100%;
  text-align: center;
}

.startpage-header .slick-dots li {
  display: inline-block;
  vertical-align: middle;
  pointer-events: all;
}

@media all and (max-width: 667px) {
  .startpage-header .slick-dots {
    bottom: 16px;
  }
}

.startpage-header .slick-dot {
  position: relative;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.startpage-header .slick-dot::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: opacity 0.3s;
  opacity: 0;
}

.startpage-header .slick-dot.is-active::before {
  opacity: 1;
}

.startpage-header .slick-dot.is-active .slick-dot-progress {
  animation: startpageDotAnimation 4s 0.7s forwards linear;
}

.startpage-header .slick-dot.is-active.animation-no-delay .slick-dot-progress {
  animation-delay: 0s;
}

.startpage-header .slick-dot-progress {
  width: 32px;
  height: 32px;
  fill: transparent;
  stroke: #ffffff;
  stroke-width: 1;
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
}

.startpage-header .slick-dot-inner {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ffffff;
}

@media all and (max-width: 667px) {
  .startpage-header {
    margin-bottom: 40px;
  }
}

.startpage-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  z-index: 10;
}

.startpage-header-item {
  position: relative;
}

.startpage-header-image img {
  display: block;
  width: 100%;
  max-height: 722px;
  aspect-ratio: 2/1;
  object-fit: cover;
}

@media all and (max-width: 530px) {
  .startpage-header-image img {
    max-height: 375px;
    aspect-ratio: 1;
  }
}

.startpage-header-content {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  width: 100%;
  max-width: 1180px;
  padding-bottom: 120px;
  color: #ffffff;
  transform: translateX(-50%);
  z-index: 20;
}

.startpage-header-content .content {
  max-width: 546px;
  margin: 0;
}

.startpage-header-content h1 {
  margin-bottom: 16px;
}

@media all and (max-width: 1280px) {
  .startpage-header-content {
    left: 0;
    padding-inline: 64px;
    transform: none;
  }
}

@media all and (max-width: 1024px) {
  .startpage-header-content {
    padding-inline: 48px;
    padding-bottom: 80px;
  }
}

@media all and (max-width: 667px) {
  .startpage-header-content {
    padding-inline: 16px;
  }
}

.startpage-read-more {
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  text-decoration: underline;
  text-underline-offset: 5px;
}

@keyframes startpageDotAnimation {
  0% {
    stroke-dashoffset: 95;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.header h1 {
  text-align: center;
  margin-bottom: 30px;
}

@media all and (max-width: 667px) {
  .header h1 {
    margin-bottom: 20px;
  }
}

.header .sub-title {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.is-lehre-main .header .sub-title {
  color: #3CB6CE;
}

:root .is-lehre .header .sub-title {
  color: #3CB6CE;
}

:root .is-facebook .header .sub-title {
  color: #3C5B9B;
}

:root .is-youtube .header .sub-title {
  color: #FE3432;
}

:root .is-twitter .header .sub-title {
  color: #2DAAE1;
}

:root .is-instagram .header .sub-title {
  color: #6A453B;
}

.header .date {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.is-lehre-main .header .date {
  color: #3CB6CE;
}

:root .is-lehre .header .date {
  color: #3CB6CE;
}

:root .is-facebook .header .date {
  color: #3C5B9B;
}

:root .is-youtube .header .date {
  color: #FE3432;
}

:root .is-twitter .header .date {
  color: #2DAAE1;
}

:root .is-instagram .header .date {
  color: #6A453B;
}

.header-image {
  position: relative;
  margin-bottom: 40px;
}

.header-image img {
  display: block;
  width: 100%;
}

@media all and (max-width: 768px) {
  .header-event .header-image {
    margin-bottom: 0;
  }
}

.block-content {
  margin-top: 20px;
}

@media all and (max-width: 667px) {
  .block-content {
    margin-top: 10px;
  }
}

.block-text-content {
  padding-right: 120px;
  color: #4A4A4A;
}

.block-text-content.center-text {
  padding-right: 0;
}

.block-text-content.has-image {
  padding-right: 0;
}

@media all and (max-width: 1024px) {
  .block-text-content {
    padding-right: 0;
  }
}

@media all and (max-width: 667px) {
  .block-text-content {
    padding-right: 0;
  }
}

@media all and (max-width: 530px) {
  .block-text-content td {
    display: block;
    width: 100%;
  }
  .block-text-content td:first-of-type {
    font-family: "PT Sans W01 Bold";
  }
  .block-text-content tr {
    display: block;
    margin-top: 10px;
  }
  .block-text-content tr:first-child {
    margin-top: 0;
  }
}

.block p, .block .block-text-content, .block .contacts .contact-position, .contacts .block .contact-position, .block .contacts .contact-info, .contacts .block .contact-info, .block .search-vcard, .block ol, .block ul {
  margin-bottom: 30px;
  color: #4A4A4A;
}

.block p:last-child, .block .block-text-content:last-child, .block .contacts .contact-position:last-child, .contacts .block .contact-position:last-child, .block .contacts .contact-info:last-child, .contacts .block .contact-info:last-child, .block .search-vcard:last-child, .block ol:last-child, .block ul:last-child {
  margin-bottom: 0;
}

@media all and (max-width: 667px) {
  .block p, .block .block-text-content, .block .contacts .contact-position, .contacts .block .contact-position, .block .contacts .contact-info, .contacts .block .contact-info, .block .search-vcard, .block ol, .block ul {
    margin-bottom: 15px;
  }
}

.block p + ol, .block .block-text-content + ol, .block .contacts .contact-position + ol, .contacts .block .contact-position + ol, .block .contacts .contact-info + ol, .contacts .block .contact-info + ol, .block .search-vcard + ol {
  margin-top: -20px;
}

@media all and (max-width: 667px) {
  .block p + ol, .block .block-text-content + ol, .block .contacts .contact-position + ol, .contacts .block .contact-position + ol, .block .contacts .contact-info + ol, .contacts .block .contact-info + ol, .block .search-vcard + ol {
    margin-top: -10px;
  }
}

.block ol, .block ul {
  list-style: none;
  margin-left: 50px;
}

@media all and (max-width: 667px) {
  .block ol, .block ul {
    margin-left: 20px;
  }
}

.block ol li {
  position: relative;
  margin-bottom: 15px;
}

.block ol li:before {
  content: " ";
  position: absolute;
  left: -30px;
  top: 8px;
  width: 8px;
  height: 8px;
  text-align: right;
}

.is-lehre-main .block ol li:before {
  background-color: #3CB6CE;
}

:root .is-lehre .block ol li:before {
  background-color: #3CB6CE;
}

:root .is-facebook .block ol li:before {
  background-color: #3C5B9B;
}

:root .is-youtube .block ol li:before {
  background-color: #FE3432;
}

:root .is-twitter .block ol li:before {
  background-color: #2DAAE1;
}

:root .is-instagram .block ol li:before {
  background-color: #6A453B;
}

@media all and (max-width: 667px) {
  .block ol li:before {
    top: 6px;
    left: -20px;
  }
}

.block ul li {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E8E8E8;
}

.block ul li:last-child {
  border-bottom: none;
}

.block ul li:before {
  content: " ";
  position: absolute;
  left: -30px;
  top: 8px;
  width: 8px;
  height: 8px;
  text-align: right;
}

.is-lehre-main .block ul li:before {
  background-color: #3CB6CE;
}

:root .is-lehre .block ul li:before {
  background-color: #3CB6CE;
}

:root .is-facebook .block ul li:before {
  background-color: #3C5B9B;
}

:root .is-youtube .block ul li:before {
  background-color: #FE3432;
}

:root .is-twitter .block ul li:before {
  background-color: #2DAAE1;
}

:root .is-instagram .block ul li:before {
  background-color: #6A453B;
}

@media all and (max-width: 667px) {
  .block ul li:before {
    top: 6px;
    left: -20px;
  }
}

.block h1 {
  letter-spacing: 0;
  color: #000000;
}

@media all and (max-width: 667px) {
  .block h1 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 10px;
  }
}

.block h2 {
  letter-spacing: 0;
  color: #000000;
}

@media all and (max-width: 667px) {
  .block h2 {
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 10px;
  }
}

.block-apprentices-title {
  margin-bottom: 30px;
}

.contacts .slick-dots {
  position: relative;
  margin-top: 50px;
  width: auto;
}

.contacts .slick-dots li {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.contacts .slick-dots li:before {
  width: 0;
  height: 0;
}

.is-lehre-main .contacts .slick-dot-inner {
  background-color: #3CB6CE;
}

:root .is-lehre .contacts .slick-dot-inner {
  background-color: #3CB6CE;
}

:root .is-facebook .contacts .slick-dot-inner {
  background-color: #3C5B9B;
}

:root .is-youtube .contacts .slick-dot-inner {
  background-color: #FE3432;
}

:root .is-twitter .contacts .slick-dot-inner {
  background-color: #2DAAE1;
}

:root .is-instagram .contacts .slick-dot-inner {
  background-color: #6A453B;
}

.is-lehre-main .contacts .slick-active .slick-dot {
  border-color: #3CB6CE;
}

:root .is-lehre .contacts .slick-active .slick-dot {
  border-color: #3CB6CE;
}

:root .is-facebook .contacts .slick-active .slick-dot {
  border-color: #3C5B9B;
}

:root .is-youtube .contacts .slick-active .slick-dot {
  border-color: #FE3432;
}

:root .is-twitter .contacts .slick-active .slick-dot {
  border-color: #2DAAE1;
}

:root .is-instagram .contacts .slick-active .slick-dot {
  border-color: #6A453B;
}

@media all and (max-width: 667px) {
  .is-lehre-main .contacts .slick-active .slick-dot {
    border-color: #3CB6CE;
  }
  :root .is-lehre .contacts .slick-active .slick-dot {
    border-color: #3CB6CE;
  }
  :root .is-facebook .contacts .slick-active .slick-dot {
    border-color: #3C5B9B;
  }
  :root .is-youtube .contacts .slick-active .slick-dot {
    border-color: #FE3432;
  }
  :root .is-twitter .contacts .slick-active .slick-dot {
    border-color: #2DAAE1;
  }
  :root .is-instagram .contacts .slick-active .slick-dot {
    border-color: #6A453B;
  }
}

.is-lehre-main .contacts .slick-active .slick-dot-inner {
  background-color: #3CB6CE;
  background-color: transparent;
}

:root .is-lehre .contacts .slick-active .slick-dot-inner {
  background-color: #3CB6CE;
  background-color: transparent;
}

:root .is-facebook .contacts .slick-active .slick-dot-inner {
  background-color: #3C5B9B;
  background-color: transparent;
}

:root .is-youtube .contacts .slick-active .slick-dot-inner {
  background-color: #FE3432;
  background-color: transparent;
}

:root .is-twitter .contacts .slick-active .slick-dot-inner {
  background-color: #2DAAE1;
  background-color: transparent;
}

:root .is-instagram .contacts .slick-active .slick-dot-inner {
  background-color: #6A453B;
  background-color: transparent;
}

.contacts .contact-name {
  display: block;
  font-size: 26px;
  line-height: 32px;
}

@media all and (max-width: 1024px) {
  .contacts .contact-name {
    font-size: 18px;
    line-height: 18px;
  }
}

.contacts .contact-position {
  display: block;
  color: #4A4A4A;
}

.contacts .contact-info {
  display: block;
}

.contacts .contact-info:last-child {
  margin-bottom: 0;
}

.contacts .contact-info.mail {
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
}

.contacts.is-slider {
  padding-left: 0;
  padding-right: 0;
}

.contacts.is-slider .slick-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contacts.is-slider .slick-track {
  display: flex;
}

:root .contacts.is-slider .slick-slide {
  height: auto;
  margin: 10px 50px;
}

@media all and (max-width: 667px) {
  :root .contacts.is-slider .slick-slide {
    margin: 10px 20px;
  }
}

.contacts.is-slider .slick-dots {
  width: 100%;
}

.contacts a {
  text-decoration: none;
}

.contacts a:hover {
  text-decoration: underline;
}

.contact-item-image {
  display: inline-block;
  vertical-align: top;
  width: 150px;
  border-radius: 50%;
  margin-right: 40px;
  overflow: hidden;
  max-width: 220px;
}

.has-small-contacts .contact-item-image {
  width: 120px;
}

.contact-item-image img {
  display: block;
  width: 100%;
}

@media all and (max-width: 1024px) {
  .contact-item-image img {
    display: inline-block;
  }
}

@media all and (max-width: 667px) {
  .has-image .contact-item-image {
    text-align: center;
    display: block;
    margin: 0 auto 20px;
  }
}

.contact-address {
  position: relative;
  margin-top: 15px;
  font-size: 16px;
  line-height: 24px;
  color: #4A4A4A;
}

.contacts-container {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  overflow: hidden;
}

@media all and (max-width: 768px) {
  .contacts-container {
    margin-top: 20px;
  }
}

.contact-item {
  display: block;
  width: 100%;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 40px 20px 40px 40px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.has-small-contacts .contact-item {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 10px);
}

.has-small-contacts .contact-item:nth-child(2n+0) {
  margin-right: 0;
  margin-left: 20px;
}

.has-small-contacts .contact-item:nth-child(-n+2) {
  margin-top: 0;
}

@media all and (max-width: 1024px) {
  .has-small-contacts .contact-item {
    width: 100%;
    margin-right: 0;
  }
  .has-small-contacts .contact-item:nth-child(-n+2) {
    margin-top: 20px;
  }
  .has-small-contacts .contact-item:nth-child(2n+0) {
    margin-left: 0;
  }
}

@media all and (max-width: 768px) {
  .has-small-contacts .contact-item {
    margin-top: auto;
  }
  .has-small-contacts .contact-item:nth-child(-n+2) {
    margin-top: auto;
  }
}

.contact-item:first-child {
  margin-top: 0;
}

@media all and (max-width: 768px) {
  .contact-item {
    margin-top: 0;
  }
}

@media all and (max-width: 667px) {
  .contact-item {
    padding: 20px;
  }
}

.contact-item-inner {
  font-size: 0;
}

.is-slider .contact-item-inner {
  margin-right: 0;
}

.contact-item-content {
  display: block;
  width: 100%;
  margin-bottom: 24px;
}

.search-contact .contact-item-content {
  margin-bottom: 0;
}

@media all and (max-width: 1024px) {
  .search-contact .contact-item-content {
    width: 100%;
  }
}

.has-image .contact-item-content {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 190px);
  margin-bottom: 0;
}

@media all and (max-width: 667px) {
  .has-image .contact-item-content {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: block;
  }
}

.contact-item-top {
  margin-bottom: 15px;
}

.contact-item-info {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.contact-item-info p, .contact-item-info .block-text-content, .contact-item-info .contacts .contact-position, .contacts .contact-item-info .contact-position, .contact-item-info .contacts .contact-info, .contacts .contact-item-info .contact-info, .contact-item-info .search-vcard {
  margin-bottom: 0;
}

.has-small-contacts .contact-item-info {
  display: block;
  width: 100%;
}

@media all and (max-width: 667px) {
  .search-contact .contact-item-info {
    width: 100%;
  }
}

.contact-item-departments {
  display: block;
  position: relative;
  padding-top: 24px;
}

.contact-item-departments p, .contact-item-departments .block-text-content, .contact-item-departments .contacts .contact-position, .contacts .contact-item-departments .contact-position, .contact-item-departments .contacts .contact-info, .contacts .contact-item-departments .contact-info, .contact-item-departments .search-vcard {
  color: #4A4A4A;
}

.search-contact .contact-item-departments {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding-top: 0;
}

.search-contact .contact-item-departments:before {
  display: none;
}

.contact-item-departments:before {
  content: '';
  position: absolute;
  left: -40px;
  top: 0;
  height: 1px;
  width: calc(100% + 60px);
  background: #E8E8E8;
}

@media all and (max-width: 667px) {
  .search-contact .contact-item-departments {
    width: 100%;
    margin-top: 20px;
  }
  .contact-item-departments:before {
    width: calc(100% + 40px);
    left: -20px;
  }
}

.search-vcard {
  display: block;
  margin-top: 20px;
}

.is-lehre-main .search-vcard {
  color: #3CB6CE;
}

:root .is-lehre .search-vcard {
  color: #3CB6CE;
}

:root .is-facebook .search-vcard {
  color: #3C5B9B;
}

:root .is-youtube .search-vcard {
  color: #FE3432;
}

:root .is-twitter .search-vcard {
  color: #2DAAE1;
}

:root .is-instagram .search-vcard {
  color: #6A453B;
}

.contact-item-info .search-vcard {
  text-decoration: underline;
}

.internal-links-container {
  font-size: 0;
  overflow: hidden;
}

.internal-links-container a {
  text-decoration: none;
}

.internal-links-container .internal-link-title {
  display: block;
  color: #ffffff;
  transform: translateY(20px);
  transition: transform 0.15s ease;
}

.internal-links-container .internal-link-more {
  display: block;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  transform: translateY(40px);
  transition: transform 0.3s ease;
}

.internal-links-container .internal-link-more [class^="icon-"], .internal-links-container .internal-link-more [class*=" icon-"] {
  font-size: 10px;
}

.touchevents .internal-links-container .internal-link-more,
.touchevents .internal-links-container .internal-link-title {
  transform: translateY(0px);
}

.internal-link-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 10px);
  margin-right: 10px;
  overflow: hidden;
}

.internal-link-item:nth-child(2n+0) {
  margin-right: 0;
  margin-left: 10px;
}

.internal-link-item:nth-child(n+3) {
  margin-top: 20px;
}

.internal-link-item:hover .internal-link-more,
.internal-link-item:hover .internal-link-title {
  transform: translateY(0px);
}

@media all and (max-width: 667px) {
  .internal-link-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .internal-link-item:nth-child(n+3) {
    margin-top: 0;
  }
  .internal-link-item:nth-child(2n+0) {
    margin-left: 0;
  }
  .internal-link-item:last-child {
    margin-bottom: 0;
  }
}

.internal-link-image img {
  width: 100%;
}

.internal-link-content {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: flex-end;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

@media all and (max-width: 667px) {
  .internal-link-content {
    padding: 20px;
  }
}

.overview {
  font-size: 0;
}

.overview-item {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 20px);
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 20px;
  color: #000000;
  font-size: 0;
  overflow: hidden;
}

.overview-item a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.overview-item.no-text {
  width: auto;
}

.overview-item.no-text .overview-content {
  display: none;
}

.overview-item.has-no-image {
  height: 220px;
}

.overview-item:nth-child(2n+0) {
  margin-right: 0;
}

.overview-item:nth-child(2n+1) {
  margin-left: 0;
}

.max-items .overview-item:nth-child(n +7) {
  display: none;
}

.max-items .overview-item:nth-child(n +7).display-article {
  display: inline-block;
  opacity: 0;
  transition: .2s ease-in;
}

.max-items .overview-item:nth-child(n +7).show-article {
  opacity: 1;
}

.touchevents .overview-item .overview-more, .overview-item:hover .overview-more {
  transform: translateY(0);
}

.touchevents .overview-item.box, .overview-item:hover.box {
  box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.1);
}

@media all and (max-width: 1024px) {
  .overview-item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media all and (max-width: 667px) {
  .overview-item:first-child {
    margin-top: 0;
  }
}

.overview-image {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 220px;
}

.overview-image img {
  width: 100%;
}

.has-no-image .overview-image {
  width: 0;
  height: 100%;
}

@media all and (max-width: 667px) {
  .overview-image {
    width: 100%;
    margin-bottom: 20px;
  }
}

.overview-content {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: calc(100% - 220px);
  color: #000000;
  padding: 0 20px;
}

.overview-content p, .overview-content .block-text-content, .overview-content .contacts .contact-position, .contacts .overview-content .contact-position, .overview-content .contacts .contact-info, .contacts .overview-content .contact-info, .overview-content .search-vcard {
  margin-bottom: 10px;
  overflow: hidden;
  max-height: 77px;
  color: #4A4A4A;
}

.has-no-image .overview-content {
  width: 100%;
}

@media all and (max-width: 667px) {
  .overview-content {
    width: 100%;
    margin-bottom: 20px;
  }
}

.overview-headline {
  margin-bottom: 10px;
  word-break: break-word;
}

@media all and (max-width: 1024px) {
  .overview-headline {
    margin-bottom: 0;
  }
}

.overview-more {
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  transform: translateY(100px);
  transition: transform 0.3s ease;
  letter-spacing: 1px;
}

.is-lehre-main .overview-more {
  color: #3CB6CE;
}

:root .is-lehre .overview-more {
  color: #3CB6CE;
}

:root .is-facebook .overview-more {
  color: #3C5B9B;
}

:root .is-youtube .overview-more {
  color: #FE3432;
}

:root .is-twitter .overview-more {
  color: #2DAAE1;
}

:root .is-instagram .overview-more {
  color: #6A453B;
}

.overview-more [class^="icon-"], .overview-more [class*=" icon-"] {
  font-size: 10px;
}

.overview-list-inner {
  column-count: 3;
  column-gap: 0;
}

@media all and (max-width: 1024px) {
  .overview-list-inner {
    column-count: 2;
  }
}

@media all and (max-width: 768px) {
  .overview-list-inner {
    column-count: 1;
  }
}

.overview-list-item {
  transition: background-color 0.15s ease;
  border-right: 1px solid #E8E8E8;
  font-size: 0;
}

.overview-list-item a {
  display: block;
  padding-left: 30px;
  text-decoration: none;
}

@media all and (max-width: 1024px) {
  .overview-list-item a {
    padding-left: 20px;
  }
}

.overview-list-item [class^="icon-"], .overview-list-item [class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  line-height: 64px;
  transition: color 0.15s ease;
}

.is-lehre-main .overview-list-item [class^="icon-"], .is-lehre-main .overview-list-item [class*=" icon-"] {
  color: #3CB6CE;
}

:root .is-lehre .overview-list-item [class^="icon-"], :root .is-lehre .overview-list-item [class*=" icon-"] {
  color: #3CB6CE;
}

:root .is-facebook .overview-list-item [class^="icon-"], :root .is-facebook .overview-list-item [class*=" icon-"] {
  color: #3C5B9B;
}

:root .is-youtube .overview-list-item [class^="icon-"], :root .is-youtube .overview-list-item [class*=" icon-"] {
  color: #FE3432;
}

:root .is-twitter .overview-list-item [class^="icon-"], :root .is-twitter .overview-list-item [class*=" icon-"] {
  color: #2DAAE1;
}

:root .is-instagram .overview-list-item [class^="icon-"], :root .is-instagram .overview-list-item [class*=" icon-"] {
  color: #6A453B;
}

@media all and (max-width: 667px) {
  .overview-list-item [class^="icon-"], .overview-list-item [class*=" icon-"] {
    line-height: 55px;
  }
}

.is-lehre-main .overview-list-item:hover {
  background-color: #3CB6CE;
}

:root .is-lehre .overview-list-item:hover {
  background-color: #3CB6CE;
}

:root .is-facebook .overview-list-item:hover {
  background-color: #3C5B9B;
}

:root .is-youtube .overview-list-item:hover {
  background-color: #FE3432;
}

:root .is-twitter .overview-list-item:hover {
  background-color: #2DAAE1;
}

:root .is-instagram .overview-list-item:hover {
  background-color: #6A453B;
}

.is-lehre-main .overview-list-item:hover [class^="icon-"], .is-lehre-main .overview-list-item:hover [class*=" icon-"] {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-lehre .overview-list-item:hover [class^="icon-"], :root .is-lehre .overview-list-item:hover [class*=" icon-"] {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-facebook .overview-list-item:hover [class^="icon-"], :root .is-facebook .overview-list-item:hover [class*=" icon-"] {
  color: #3C5B9B;
  color: #ffffff;
}

:root .is-youtube .overview-list-item:hover [class^="icon-"], :root .is-youtube .overview-list-item:hover [class*=" icon-"] {
  color: #FE3432;
  color: #ffffff;
}

:root .is-twitter .overview-list-item:hover [class^="icon-"], :root .is-twitter .overview-list-item:hover [class*=" icon-"] {
  color: #2DAAE1;
  color: #ffffff;
}

:root .is-instagram .overview-list-item:hover [class^="icon-"], :root .is-instagram .overview-list-item:hover [class*=" icon-"] {
  color: #6A453B;
  color: #ffffff;
}

.overview-list-item:hover .overview-list-title {
  color: #ffffff;
}

@media all and (max-width: 667px) {
  .overview-list-item {
    border-bottom: 1px solid #E8E8E8;
  }
  .overview-list-item:last-child {
    border-bottom: none;
  }
}

.overview-list-title {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 15px);
  font-size: 20px;
  line-height: 34px;
  color: #000000;
  padding-left: 16px;
  transition: color 0.15s ease;
}

@media all and (max-width: 1024px) {
  .overview-list-title {
    padding-left: 10px;
  }
}

@media all and (max-width: 667px) {
  .overview-list-title {
    font-size: 22px;
    line-height: 28px;
  }
}

@media all and (max-width: 530px) {
  .overview-list-title {
    font-size: 15px;
  }
}

.overview-article-item {
  border-bottom: 1px solid #E8E8E8;
}

.overview-article-item:last-child {
  border-bottom: none;
}

.overview-article-item a {
  display: block;
  padding: 20px 30px 20px;
  font-size: 0;
}

.overview-article-title {
  color: #000000;
}

@media all and (max-width: 667px) {
  .overview-article-title {
    margin-bottom: 8px;
  }
}

.overview-article-description {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.overview-article-description strong {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.overview-article-tag {
  line-height: 20px;
  margin-bottom: 4px;
  display: block;
}

.is-lehre-main .overview-article-tag {
  color: #3CB6CE;
}

:root .is-lehre .overview-article-tag {
  color: #3CB6CE;
}

:root .is-facebook .overview-article-tag {
  color: #3C5B9B;
}

:root .is-youtube .overview-article-tag {
  color: #FE3432;
}

:root .is-twitter .overview-article-tag {
  color: #2DAAE1;
}

:root .is-instagram .overview-article-tag {
  color: #6A453B;
}

.overview-article-content {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 40px);
}

.overview-article-arrow {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  text-align: right;
}

.overview-article-arrow [class^="icon-"], .overview-article-arrow [class*=" icon-"] {
  font-size: 12px;
  color: #000000;
}

.overview-articles h2 {
  margin-bottom: 30px;
  margin-top: 40px;
}

.overview-articles h2.no-top-space {
  margin-top: 0;
}

.event-headline {
  margin-top: 30px;
}

@media all and (max-width: 1024px) {
  .event-headline {
    margin-top: 0;
  }
}

.event-filter {
  display: flex;
}

.event-filter .btn {
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
}

@media all and (max-width: 667px) {
  .event-filter .btn {
    font-size: 16px;
    line-height: 24px;
  }
}

.event-filter-item, .event-filter-item2 {
  display: inline-block;
  vertical-align: top;
  width: 33.3333%;
  padding: 0 10px;
  text-align: left;
}

.event-filter-item:first-child, .event-filter-item2:first-child {
  padding-left: 0;
}

.event-filter-item:last-child, .event-filter-item2:last-child {
  padding-right: 0;
}

.event-filter-item.has-full-width, .has-full-width.event-filter-item2 {
  width: 66.6666%;
}

.event-filter-item .styled-select li, .event-filter-item2 .styled-select li {
  border-bottom: solid 1px #E8E8E8;
}

@media all and (max-width: 1024px) {
  .event-filter-item, .event-filter-item2 {
    width: 50%;
  }
}

@media all and (max-width: 667px) {
  .event-filter-item, .event-filter-item2 {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
  }
}

@media all and (max-width: 1024px) {
  .event-filter-add {
    display: block;
    margin: 20px auto 0;
  }
}

@media all and (max-width: 667px) {
  .event-filter-add {
    margin-top: 0;
  }
}

.event-overview-inner {
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.event-overview-title {
  margin-bottom: 30px;
}

.event-overview-item {
  border-bottom: 1px solid #E8E8E8;
  padding: 20px 30px;
}

.event-overview-item:last-child {
  border-bottom: none;
}

.event-overview-item-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000000;
}

.event-overview-item-inner:hover {
  text-decoration: none;
}

@media all and (max-width: 768px) {
  .event-overview-item-inner {
    display: block;
  }
}

.event-overview-image {
  width: 120px;
  flex: 0 0 120px;
}

@media all and (max-width: 1024px) {
  .event-overview-image {
    display: none;
  }
}

.event-overview-date {
  width: 340px;
  flex: 0 0 340px;
  color: #4A4A4A;
  font-size: 14px;
  padding-right: 20px;
}

@media all and (max-width: 1024px) {
  .event-overview-date {
    width: 190px;
    flex: 0 0 190px;
  }
}

@media all and (max-width: 768px) {
  .event-overview-date {
    width: 100%;
  }
}

.event-overview-more {
  width: 30px;
  flex: 0 0 30px;
  font-size: 10px;
}

.is-lehre-main .event-overview-more {
  color: #3CB6CE;
}

:root .is-lehre .event-overview-more {
  color: #3CB6CE;
}

:root .is-facebook .event-overview-more {
  color: #3C5B9B;
}

:root .is-youtube .event-overview-more {
  color: #FE3432;
}

:root .is-twitter .event-overview-more {
  color: #2DAAE1;
}

:root .is-instagram .event-overview-more {
  color: #6A453B;
}

@media all and (max-width: 768px) {
  .event-overview-more {
    display: none;
  }
}

.event-overview-content {
  color: #000000;
  width: calc(100% - 30px - 340px - 120px);
  flex: 1 0 calc(100% - 30px - 340px - 120px);
  padding-right: 20px;
  padding-left: 20px;
}

@media all and (max-width: 768px) {
  .event-overview-content {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
}

.event-overview-location {
  margin-bottom: 5px;
}

.is-lehre-main .event-overview-location {
  color: #3CB6CE;
}

:root .is-lehre .event-overview-location {
  color: #3CB6CE;
}

:root .is-facebook .event-overview-location {
  color: #3C5B9B;
}

:root .is-youtube .event-overview-location {
  color: #FE3432;
}

:root .is-twitter .event-overview-location {
  color: #2DAAE1;
}

:root .is-instagram .event-overview-location {
  color: #6A453B;
}

.event-overview-button {
  margin-top: 40px;
  text-align: right;
}

.event-submit-form-container {
  display: block;
  position: relative;
  max-width: 840px;
  margin: 140px auto 0;
  padding: 80px 70px 140px;
  background-color: #F2F2F2;
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.event-submit-form-container h1 {
  font-size: 36px;
  color: #000000;
  letter-spacing: 1px;
  line-height: 36px;
}

.event-submit-form-container p, .event-submit-form-container .block-text-content, .event-submit-form-container .contacts .contact-position, .contacts .event-submit-form-container .contact-position, .event-submit-form-container .contacts .contact-info, .contacts .event-submit-form-container .contact-info, .event-submit-form-container .search-vcard {
  padding-top: 30px;
}

.event-submit-form-container.is-success-message {
  padding-bottom: 90px;
}

.event-submit-form-container .icon-close, .event-submit-form-container .is-open .search-btn [class^="icon-"], .is-open .search-btn .event-submit-form-container [class^="icon-"], .event-submit-form-container .is-open .search-btn [class*=" icon-"], .is-open .search-btn .event-submit-form-container [class*=" icon-"] {
  position: absolute;
  font-size: 45px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

@media all and (max-width: 667px) {
  .event-submit-form-container {
    padding: 50px 20px 100px;
    margin-top: 0;
  }
}

.event-submit-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: none;
  opacity: 0;
  transition: opacity .3s ease-in;
}

.event-submit-wrapper.show {
  opacity: 1;
}

.event-submit-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.event-submit-overlay.loading {
  z-index: 2;
}

.event-submit-loading {
  position: fixed;
  display: none;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  z-index: 3;
}

.loading .event-submit-loading {
  display: block;
}

.event-submit-form {
  margin-left: -20px;
  font-size: 0;
}

.event-form-item {
  padding-top: 30px;
  padding-left: 20px;
}

.event-form-item.half {
  width: 50%;
  display: inline-block;
}

@media all and (max-width: 667px) {
  .event-form-item.half {
    width: 100%;
  }
}

@media all and (max-width: 667px) {
  .event-form-item {
    padding-top: 20px;
  }
}

.event-submit-input.event-input-hidden {
  opacity: 0;
  position: absolute;
  width: 0;
}

.event-submit-label {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.event-submit-label.event-image-submit {
  display: inline-block;
  position: relative;
  width: 25%;
  min-width: 120px;
  background-color: #ffffff;
  border: 1px solid #E8E8E8;
  border-left: none;
  vertical-align: top;
  line-height: 54px;
  float: right;
  text-align: center;
  cursor: pointer;
  height: 100%;
  z-index: 1;
}

.is-lehre-main .event-submit-label.event-image-submit {
  color: #3CB6CE;
}

:root .is-lehre .event-submit-label.event-image-submit {
  color: #3CB6CE;
}

:root .is-facebook .event-submit-label.event-image-submit {
  color: #3C5B9B;
}

:root .is-youtube .event-submit-label.event-image-submit {
  color: #FE3432;
}

:root .is-twitter .event-submit-label.event-image-submit {
  color: #2DAAE1;
}

:root .is-instagram .event-submit-label.event-image-submit {
  color: #6A453B;
}

:root .event-submit-label.event-image-submit:after {
  content: '';
}

.is-lehre-main .event-submit-label.event-image-submit:hover {
  background-color: #3CB6CE;
}

:root .is-lehre .event-submit-label.event-image-submit:hover {
  background-color: #3CB6CE;
}

:root .is-facebook .event-submit-label.event-image-submit:hover {
  background-color: #3C5B9B;
}

:root .is-youtube .event-submit-label.event-image-submit:hover {
  background-color: #FE3432;
}

:root .is-twitter .event-submit-label.event-image-submit:hover {
  background-color: #2DAAE1;
}

:root .is-instagram .event-submit-label.event-image-submit:hover {
  background-color: #6A453B;
}

.is-lehre-main .event-submit-label.event-image-submit:hover {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-lehre .event-submit-label.event-image-submit:hover {
  color: #3CB6CE;
  color: #ffffff;
}

:root .is-facebook .event-submit-label.event-image-submit:hover {
  color: #3C5B9B;
  color: #ffffff;
}

:root .is-youtube .event-submit-label.event-image-submit:hover {
  color: #FE3432;
  color: #ffffff;
}

:root .is-twitter .event-submit-label.event-image-submit:hover {
  color: #2DAAE1;
  color: #ffffff;
}

:root .is-instagram .event-submit-label.event-image-submit:hover {
  color: #6A453B;
  color: #ffffff;
}

@media all and (max-width: 667px) {
  .event-submit-label.event-image-submit {
    font-size: 10px;
  }
}

.event-submit-label.required:after {
  content: '*';
}

.event-submit-button {
  position: absolute;
  right: 70px;
  margin-top: 30px;
  cursor: pointer;
  outline: 0;
}

.event-file-input {
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  width: 75%;
  max-width: calc(100% - 120px);
  background-color: #ffffff;
  border: 1px solid #E8E8E8;
}

.event-submit-description {
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #E8E8E8;
  width: 100%;
  height: 200px;
  font-size: 20px;
  line-height: 20px;
  padding: 15px 20px;
  resize: none;
}

@media all and (max-width: 667px) {
  .event-submit-description {
    font-size: 16px;
  }
}

.is-lehre-main .event-route {
  color: #3CB6CE;
}

:root .is-lehre .event-route {
  color: #3CB6CE;
}

:root .is-facebook .event-route {
  color: #3C5B9B;
}

:root .is-youtube .event-route {
  color: #FE3432;
}

:root .is-twitter .event-route {
  color: #2DAAE1;
}

:root .is-instagram .event-route {
  color: #6A453B;
}

.maps {
  width: 100%;
  padding-top: 49%;
  position: relative;
}

.maps-view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.companies-filter {
  font-size: 0;
  text-align: center;
}

@media all and (max-width: 1024px) {
  .companies-filter .event-filter-item, .companies-filter .event-filter-item2 {
    padding-bottom: 20px;
  }
  .companies-filter .event-filter-item:nth-child(2), .companies-filter .event-filter-item2:nth-child(2) {
    padding-right: 0;
  }
}

@media all and (max-width: 667px) {
  .companies-filter {
    padding-bottom: 10px;
  }
  .companies-filter .event-filter-item:last-child, .companies-filter .event-filter-item2:last-child {
    padding-top: 0;
  }
}

.event-filter-item2 {
  display: none;
}

.event-filter-item2.show-item {
  display: inline-block;
}

.companies-search-item {
  position: relative;
  display: block;
  padding: 17px 20px;
}

@media all and (max-width: 667px) {
  .companies-search-item {
    font-size: 16px;
    padding: 9px 20px;
  }
}

.companies-search-icon {
  position: absolute;
  height: 60px;
  overflow: hidden;
  width: 40px;
  right: 0;
}

.companies-search-icon:before {
  font-family: 'lustenau';
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 20px;
  line-height: 58px;
  color: #000000;
  cursor: pointer;
  z-index: 1;
}

@media all and (max-width: 667px) {
  .companies-search-icon:before {
    line-height: 40px;
  }
}

.companies-content {
  display: block;
  position: relative;
}

.companies-content.is-enabled {
  display: none;
}

.companies-content.active {
  display: block;
}

@media all and (max-width: 667px) {
  .companies-content h2 {
    text-align: left;
  }
}

.company-item-wrapper {
  display: block;
  padding-bottom: 0;
  font-size: 0;
}

.company-item-wrapper.is-enabled {
  display: none;
}

.company-item-wrapper:first-of-type {
  margin-top: 10px;
}

.company-item-wrapper:last-of-type {
  margin-bottom: 40px;
}

.company-item {
  position: relative;
  display: flex;
  padding: 20px 40px 20px 30px;
  border-bottom: 1px solid #e8e8e8;
}

.company-item .company-image {
  min-width: 240px;
}

@media all and (max-width: 667px) {
  .company-item {
    flex-direction: column;
    padding: 20px;
  }
  .company-item img {
    width: 100%;
  }
}

.company-content-wrapper {
  position: relative;
  width: 100%;
}

.company-content-container {
  display: flex;
  position: relative;
  width: 100%;
  padding-left: 30px;
  padding-right: 140px;
}

.company-content-container h3, .company-content-container .block h1, .block .company-content-container h1 {
  margin-bottom: 7px;
}

.has-no-image .company-content-container {
  padding: 0;
}

.company-content-container .company-content-more-link {
  text-decoration: none;
}

@media all and (max-width: 800px) {
  .company-content-container {
    padding-right: 0;
    flex-direction: column;
  }
}

@media all and (max-width: 667px) {
  .company-content-container {
    padding: 20px 0 40px;
  }
}

.company-content-text {
  display: flex;
  flex-direction: column;
}

.company-content-title {
  color: #000000;
  text-decoration: none;
}

.company-content-title:hover {
  text-decoration: none;
}

.company-content-contact {
  margin-top: 5px;
}

.is-lehre-main .company-content-contact a {
  color: #3CB6CE;
}

:root .is-lehre .company-content-contact a {
  color: #3CB6CE;
}

:root .is-facebook .company-content-contact a {
  color: #3C5B9B;
}

:root .is-youtube .company-content-contact a {
  color: #FE3432;
}

:root .is-twitter .company-content-contact a {
  color: #2DAAE1;
}

:root .is-instagram .company-content-contact a {
  color: #6A453B;
}

.company-content-more {
  position: absolute;
  display: flex;
  right: 0;
  align-self: center;
  cursor: pointer;
  height: 100%;
}

.is-lehre-main .company-content-more {
  color: #3CB6CE;
}

:root .is-lehre .company-content-more {
  color: #3CB6CE;
}

:root .is-facebook .company-content-more {
  color: #3C5B9B;
}

:root .is-youtube .company-content-more {
  color: #FE3432;
}

:root .is-twitter .company-content-more {
  color: #2DAAE1;
}

:root .is-instagram .company-content-more {
  color: #6A453B;
}

.company-content-more.arrow-right i {
  font-size: 12px;
  margin-right: 0;
  margin-left: 10px;
}

.company-content-more i {
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-self: center;
  margin-right: 8px;
}

.company-content-more p, .company-content-more .block-text-content, .company-content-more .contacts .contact-position, .contacts .company-content-more .contact-position, .company-content-more .contacts .contact-info, .contacts .company-content-more .contact-info, .company-content-more .search-vcard {
  align-self: center;
}

.company-content-more .icon-arrow-down.rotate {
  transform: rotate(180deg);
}

@media all and (max-width: 800px) {
  .company-content-more {
    position: relative;
    height: auto;
    bottom: 0;
    left: 0;
    align-self: auto;
    margin-top: 10px;
  }
}

@media all and (max-width: 667px) {
  .company-content-more {
    left: 0;
  }
}

.company-content-text-more {
  max-height: 0;
  padding-left: 30px;
  overflow: hidden;
  transition: .3s linear;
}

.company-content-text-more.show-item {
  margin-top: 40px;
  max-height: 900px;
}

@media all and (max-width: 667px) {
  .company-content-text-more.show-item {
    margin-top: 0;
  }
}

.has-no-image .company-content-text-more {
  padding-left: 0;
}

@media all and (max-width: 667px) {
  .company-content-text-more {
    padding-left: 0;
  }
}

.show-more {
  display: block;
  font-size: 16px;
  line-height: 24px;
}

.show-more.remove-text {
  display: none;
}

.show-less {
  display: none;
  font-size: 16px;
  line-height: 24px;
}

.show-less.show-text {
  display: block;
}

.form-content {
  width: calc(100% + 20px);
  margin-left: -10px;
  overflow: hidden;
}

.form-content .btn {
  outline: 0;
}

.form-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 30px;
  width: 100%;
  padding: 0 10px;
}

.form-item.form-width-half {
  width: 50%;
}

@media all and (max-width: 530px) {
  .form-item.form-width-half {
    width: 100%;
  }
}

.form-item.form-width-one-third {
  width: 33.3333%;
}

@media all and (max-width: 530px) {
  .form-item.form-width-one-third {
    width: 100%;
  }
}

.form-item.form-width-two-thirds {
  width: 66.6666%;
}

@media all and (max-width: 530px) {
  .form-item.form-width-two-thirds {
    width: 100%;
  }
}

.form-item.form-width-one-quarter {
  width: 25%;
}

@media all and (max-width: 530px) {
  .form-item.form-width-one-quarter {
    width: 100%;
  }
}

.form-item.form-width-three-quarters {
  width: 75%;
}

@media all and (max-width: 530px) {
  .form-item.form-width-three-quarters {
    width: 100%;
  }
}

.form-label {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  word-break: break-word;
}

.checkbox {
  position: relative;
  margin-top: 12px;
}

.checkbox input[type='checkbox'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.checkbox input:checked + .form-label::after {
  color: #000000;
  position: absolute;
  font-family: "lustenau";
}

.checkbox .form-label {
  display: block;
  position: relative;
  padding-left: 40px;
  text-transform: unset;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 18px;
}

.no-touchevents .checkbox .form-label:hover::before {
  border: 1px solid #000000;
}

.checkbox .form-label::before, .checkbox .form-label::after {
  content: '';
  position: absolute;
  transform: translateY(-50%);
  top: 12px;
}

.checkbox .form-label::before {
  width: 23px;
  height: 23px;
  left: 0;
  border: 1px solid #000000;
  border-radius: 5px;
}

.checkbox .form-label::after {
  font-size: 18px;
  left: 3px;
  font-weight: 500;
}

.radio-buttons {
  display: block;
  position: relative;
  margin-top: 12px;
}

.radio-buttons input[type='radio'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.is-lehre-main .radio-buttons input:checked + .form-label::after {
  background-color: #3CB6CE;
}

:root .is-lehre .radio-buttons input:checked + .form-label::after {
  background-color: #3CB6CE;
}

:root .is-facebook .radio-buttons input:checked + .form-label::after {
  background-color: #3C5B9B;
}

:root .is-youtube .radio-buttons input:checked + .form-label::after {
  background-color: #FE3432;
}

:root .is-twitter .radio-buttons input:checked + .form-label::after {
  background-color: #2DAAE1;
}

:root .is-instagram .radio-buttons input:checked + .form-label::after {
  background-color: #6A453B;
}

.radio-buttons input[value=''] + .form-label {
  display: none;
}

.radio-buttons .form-label {
  position: relative;
  display: block;
  padding-left: 40px;
  font-weight: 400;
  text-transform: unset;
  margin: 0 0 16px;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
}

.radio-buttons .form-label:last-of-type {
  margin-right: 0;
}

.radio-buttons .form-label::before {
  content: '';
  position: absolute;
  width: 22px;
  height: 22px;
  left: 0;
  border-radius: 50%;
  top: 1px;
  font-weight: 700;
  border: 1px solid #000000;
}

.radio-buttons .form-label::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 0;
  left: 6px;
  top: 7px;
}

.radio-buttons .form-choice-item-wrapper {
  position: relative;
  display: block;
  margin-bottom: 16px;
}

/************************************
 ** Attachment field (Dropzone).
 ***********************************/
.form-files {
  display: block;
  min-height: 95px;
  position: relative;
  width: 100%;
  background: white;
  border: 1px dashed grey;
  cursor: pointer;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0;
}

.no-touchevents .form-files:hover {
  border: 1px dashed #000000;
}

.form-files.selected-files::after {
  display: none;
}

.form-files.is-dragover {
  border: 1px dashed #000000;
}

.form-files-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  color: #000000;
  padding-right: 25px;
  pointer-events: none;
}

.form-files-inner {
  display: block;
  margin-right: 0;
  margin-left: -5px;
  width: calc(100% + 10px);
  pointer-events: none;
}

.form-files-preview {
  display: inline-block;
  width: 50%;
  height: 80px;
  text-align: center;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  z-index: 2;
}

.form-files-preview-inner {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F2F2F2;
  padding: 10px 30px;
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
}

.form-files-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  color: red;
  font-size: 12px;
  pointer-events: auto;
}

.form-container--dark .form-files-close {
  color: red;
}

.form-files-preview-title {
  display: block;
  width: 100%;
  color: #000000;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-files-preview-data {
  color: #000000;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
}

.form-files-preview-error,
.form-error-message-dropzone {
  display: block;
  width: 100%;
  color: red;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.form-error-message-dropzone {
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin-top: 5px;
}

.form-success {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
}

.form-submit[disabled] {
  background: #d8d8d8 !important;
  border-color: #d8d8d8 !important;
  pointer-events: none;
  cursor: default;
}

.form-honung {
  display: none;
}

.events {
  position: relative;
  display: block;
  padding-top: 60px;
  border-top: 1px solid #e8e8e8;
}

.events .btn-white {
  max-width: 210px;
}

.events-wrapper {
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 #E8E8E8;
  border: 1px solid #E8E8E8;
  margin-bottom: 60px;
  padding-bottom: 0;
  margin-top: 10px;
  font-size: 0;
}

.event-content-wrapper {
  display: flex;
  position: relative;
  width: 100%;
}

@media all and (max-width: 1024px) {
  .event-content-wrapper {
    flex-direction: column;
  }
}

.event-arrow-wrapper {
  display: flex;
  position: relative;
  align-self: center;
  height: 100%;
}

.event-arrow-wrapper .icon-arrow-right {
  position: absolute;
  display: flex;
  align-self: center;
  right: 0;
  font-size: 10px;
}

.is-lehre-main .event-arrow-wrapper .icon-arrow-right {
  color: #3CB6CE;
}

:root .is-lehre .event-arrow-wrapper .icon-arrow-right {
  color: #3CB6CE;
}

:root .is-facebook .event-arrow-wrapper .icon-arrow-right {
  color: #3C5B9B;
}

:root .is-youtube .event-arrow-wrapper .icon-arrow-right {
  color: #FE3432;
}

:root .is-twitter .event-arrow-wrapper .icon-arrow-right {
  color: #2DAAE1;
}

:root .is-instagram .event-arrow-wrapper .icon-arrow-right {
  color: #6A453B;
}

@media all and (max-width: 1024px) {
  .event-arrow-wrapper {
    position: absolute;
    right: 0;
  }
}

.event-more-wrapper {
  position: relative;
  display: flex;
  width: 50%;
  max-width: 320px;
  align-items: center;
  padding-right: 15px;
  margin-left: auto;
}

.event-more-wrapper p, .event-more-wrapper .block-text-content, .event-more-wrapper .contacts .contact-position, .contacts .event-more-wrapper .contact-position, .event-more-wrapper .contacts .contact-info, .contacts .event-more-wrapper .contact-info, .event-more-wrapper .search-vcard {
  color: #4A4A4A;
}

@media all and (max-width: 1024px) {
  .event-more-wrapper {
    width: 100%;
    margin-left: 30px;
  }
}

@media all and (max-width: 667px) {
  .event-more-wrapper {
    width: 100%;
    margin-left: 0;
  }
}

.events-item {
  position: relative;
  display: flex;
  padding: 20px 40px 20px 30px;
  border-bottom: 1px solid #e8e8e8;
}

.events-item a {
  text-decoration: none;
}

.events-item:last-child {
  border-bottom: none;
}

.events-item:nth-child(n + 6) {
  display: none;
}

.events-item:nth-child(n + 6).display-article {
  display: flex;
  opacity: 0;
  transition: .2s ease-in;
}

.events-item:nth-child(n + 6).show-article {
  opacity: 1;
}

.event-link {
  display: flex;
  width: 100%;
  text-decoration: none;
}

.event-image {
  position: relative;
  display: flex;
}

.event-image img {
  max-width: 120px;
  display: flex;
  align-self: center;
}

@media all and (max-width: 667px) {
  .event-image {
    display: none;
  }
}

.event-description {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 50%;
  padding: 11px 20px 0 0;
  margin-left: 30px;
}

.event-description .event-title {
  color: #000000;
}

@media all and (max-width: 1024px) {
  .event-description {
    width: calc(100% - 30px);
  }
}

@media all and (max-width: 667px) {
  .event-description {
    width: 100%;
    margin-left: 0;
  }
}

.quicklinks {
  border-bottom: solid;
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 50px;
  margin-top: 60px;
}

@media all and (max-width: 667px) {
  .quicklinks {
    margin-bottom: 30px;
  }
}

.quicklinks-items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 0;
  margin-top: 20px;
}

.startpage-quicklink {
  display: inline-block;
  width: calc((100% - 40px) / 3);
  vertical-align: bottom;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 #E8E8E8;
  border: 1px solid #E8E8E8;
}

.startpage-quicklink:nth-child(3n + 1) {
  margin-right: 20px;
}

.startpage-quicklink:nth-child(3n + 2) {
  margin-right: 20px;
}

.startpage-quicklink:nth-child(n + 4) {
  margin-top: 20px;
}

.startpage-quicklink a {
  text-decoration: none;
}

@media all and (max-width: 1024px) {
  .startpage-quicklink {
    width: calc((100% - 20px) / 2);
  }
  .startpage-quicklink:nth-child(3n + 1) {
    margin-right: auto;
  }
  .startpage-quicklink:nth-child(3n + 2) {
    margin-right: auto;
  }
  .startpage-quicklink:nth-child(2n + 1) {
    margin-right: 20px;
  }
  .startpage-quicklink:nth-child(n + 3) {
    margin-top: 20px;
  }
}

@media all and (max-width: 667px) {
  .startpage-quicklink {
    width: 100%;
    margin-top: 10px;
  }
  .startpage-quicklink:nth-child(2n + 1) {
    margin-right: auto;
  }
  .startpage-quicklink:nth-child(n + 3) {
    margin-top: 10px;
  }
  .startpage-quicklink:first-child {
    margin-top: 0;
  }
}

.startpage-quicklink-inner {
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 36px;
  color: #000000;
  padding: 20px;
  text-align: center;
  max-height: 200px;
  height: 100%;
  min-height: 80px;
}

.startpage-quicklink-inner:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

@media all and (max-width: 667px) {
  .startpage-quicklink-inner {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    font-size: 25px;
    min-height: 60px;
  }
  .startpage-quicklink-inner i {
    display: flex;
    align-self: center;
  }
  .startpage-quicklink-inner h3, .startpage-quicklink-inner .block h1, .block .startpage-quicklink-inner h1 {
    display: flex;
    align-self: center;
    margin-left: 20px;
    padding-top: 0;
  }
}

.startpage-qucklink-title {
  font-size: 20px;
  line-height: 20px;
  text-align: left;
}

.quicklink-img {
  position: relative;
  display: block;
  max-width: 36px;
  margin-right: 20px;
}

@media all and (max-width: 667px) {
  .quicklink-img {
    margin: 0;
  }
}

.content-max-width {
  position: relative;
  z-index: 1;
}

.item-list-wrapper {
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 #E8E8E8;
  border: 1px solid #E8E8E8;
  padding: 15px 10px 15px 50px;
}

.item-list-wrapper:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}

.item-list-wrapper a {
  text-decoration: none;
}

.item-list-wrapper:last-child {
  border-bottom: none;
}

@media all and (max-width: 530px) {
  .item-list-wrapper {
    padding: 15px 10px 15px 30px;
  }
}

.item-list-content-wrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.item-list-arrow-wrapper {
  position: relative;
  height: 100%;
}

.item-list-arrow-wrapper .icon-arrow-right {
  color: #3CB6CE;
  position: absolute;
  right: 0;
  font-size: 10px;
  line-height: 28px;
}

.item-list-description {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-left: 30px;
}

.item-list-description .item-list-title {
  color: #000000;
  font-size: 18px;
  line-height: 28px;
}

.article-wrapper {
  display: block;
  position: relative;
  margin: 0 auto;
}

.center-wrapper {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 30px;
  font-size: 0;
}

@media all and (max-width: 1340px) {
  .page-header {
    padding: 0 24px;
  }
}

@media all and (max-width: 1340px) {
  .search-btn-inner svg {
    width: 25px;
    height: 25px;
  }
}

.main-nav-item {
  margin-right: 48px;
}

@media all and (max-width: 1340px) {
  .main-nav-item {
    margin-right: 35px;
  }
}

@media all and (max-width: 1024px) {
  .main-nav-item {
    margin-right: 0;
  }
}

.main-nav-title {
  font-size: 24px;
  line-height: 32px;
}

@media all and (max-width: 1340px) {
  .main-nav-title {
    font-size: 22px;
    line-height: 30px;
  }
}

@media all and (max-width: 1200px) {
  .main-nav-title {
    font-size: 18px;
    line-height: 26px;
  }
}

@media all and (max-width: 1024px) {
  .main-nav-title {
    font-size: 24px;
    line-height: 32px;
  }
}

.startpage-article {
  display: flex;
  width: calc((100% - 40px) / 3);
  vertical-align: bottom;
  padding: 0;
}

.startpage-article:nth-child(3n + 1) {
  margin-right: 20px;
}

.startpage-article:nth-child(3n + 2) {
  margin-right: 20px;
}

.startpage-article:nth-child(n + 4) {
  margin-top: 20px;
}

.startpage-article:nth-child(n + 18) {
  display: flex;
}

@media all and (max-width: 1024px) {
  .startpage-article {
    width: calc((100% - 20px) / 2);
  }
  .startpage-article:nth-child(2n + 2) {
    margin-right: auto;
  }
  .startpage-article:nth-child(2n + 1) {
    margin-right: 20px;
  }
  .startpage-article:nth-child(n + 3) {
    margin-top: 20px;
  }
}

@media all and (max-width: 667px) {
  .startpage-article {
    width: 100%;
  }
  :root .startpage-article {
    margin: 10px auto;
  }
  .startpage-article:nth-child(2n + 1) {
    margin-right: auto;
  }
}

.startpage-article-inner {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.startpage-article-content {
  padding: 30px 40px 85px;
  position: relative;
}

.startpage-article-button {
  position: absolute;
  bottom: 30px;
}

.lehre-overview-button {
  position: relative;
  margin-top: 30px;
}

.lehre-overview-button:nth-of-type(1) {
  margin-right: 25px;
}

@media all and (max-width: 530px) {
  .lehre-overview-button {
    display: block;
    max-width: 180px;
    margin: 30px auto 0;
  }
  .lehre-overview-button:nth-of-type(1) {
    margin: 30px auto 0;
  }
}

.lehre-job-overview-head {
  position: relative;
  z-index: 1;
}

#apprentice-open,
#apprentice-all {
  display: block;
  height: 125px;
  margin-top: -125px;
  visibility: hidden;
}

.business-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
}

:root .business-title-wrapper h1 {
  margin-bottom: 0;
}

@media all and (max-width: 530px) {
  .business-title-wrapper {
    display: block;
  }
}

.business-logo {
  display: flex;
  position: relative;
  margin-right: 20px;
}

@media all and (max-width: 530px) {
  .business-logo {
    display: block;
    margin: 0 auto 20px;
  }
}

.business-title {
  display: flex;
  position: relative;
}

.contact-block-wrapper {
  position: relative;
  display: inline-block;
}

@media all and (max-width: 800px) {
  .contact-block-wrapper {
    width: 100%;
  }
}

.contact-block-container {
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 #E8E8E8;
  border: 1px solid #E8E8E8;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 20px;
}

@media all and (max-width: 530px) {
  .contact-block-container {
    flex-direction: column;
  }
}

.contact-block-img {
  display: flex;
  position: relative;
  width: 100%;
}

.contact-block-detail {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px 10px;
  justify-content: center;
}

.contact-block-detail .contact-block-text p, .contact-block-detail .contact-block-text .block-text-content, .contact-block-detail .contact-block-text .contacts .contact-position, .contacts .contact-block-detail .contact-block-text .contact-position, .contact-block-detail .contact-block-text .contacts .contact-info, .contacts .contact-block-detail .contact-block-text .contact-info, .contact-block-detail .contact-block-text .search-vcard {
  margin-bottom: 0;
}

@media all and (max-width: 530px) {
  .contact-block-detail {
    align-items: left;
  }
}

.entry-form-wrapper {
  position: relative;
  display: block;
  font-size: 0;
  margin: 50px 0;
}

.entry-form-title {
  text-align: center;
  font-size: 36px;
  color: #000000;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 20px;
}

.entry-form-item {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.entry-form-input {
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #E8E8E8;
}

.entry-form-description {
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #E8E8E8;
  width: 100%;
  height: 200px;
  font-size: 20px;
  line-height: 20px;
  padding: 15px 20px;
  resize: none;
}

.entry-form-label {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.entry-form-label.required:after {
  content: '*';
}

.entry-form-zip {
  display: inline-block;
  position: relative;
  width: calc(30% - 10px);
  margin-right: 20px;
}

@media all and (max-width: 530px) {
  .entry-form-zip {
    width: 100%;
    margin-right: 0;
  }
}

.entry-form-location {
  display: inline-block;
  position: relative;
  width: calc(70% - 10px);
}

@media all and (max-width: 530px) {
  .entry-form-location {
    width: 100%;
  }
}

.entry-form-half, .entry-form-wrapper .styled-select.entry-form-half {
  display: inline-block;
  position: relative;
  width: calc(50% - 10px);
  vertical-align: bottom;
}

.entry-form-half:nth-of-type(2n), .entry-form-wrapper .styled-select.entry-form-half:nth-of-type(2n) {
  margin-right: 20px;
}

@media all and (max-width: 530px) {
  .entry-form-half, .entry-form-wrapper .styled-select.entry-form-half {
    width: 100%;
  }
  .entry-form-half:nth-of-type(2n), .entry-form-wrapper .styled-select.entry-form-half:nth-of-type(2n) {
    margin-right: 0;
  }
}

.entry-form-submit {
  position: relative;
  cursor: pointer;
  margin-left: auto;
  z-index: 2;
  outline: 0;
}

.entry-form-submit.btn {
  display: block;
}

.entry-form-wrapper .styled-select.entry-form-half {
  cursor: auto;
  border: none;
  background: none;
  min-width: 420px;
}

.entry-form-wrapper .styled-select.entry-form-half:before {
  content: none;
}

.entry-form-wrapper .styled-select.apprentice-dropdown {
  min-width: 0;
}

.entry-form-wrapper .styled-select.apprentice-dropdown .selectric-wrapper {
  width: 100%;
  margin-top: 5px;
}

.entry-form-wrapper .styled-select.apprentice-dropdown .label {
  line-height: 54px;
}

.entry-form-wrapper .styled-select.apprentice-dropdown .button:after {
  line-height: 54px;
}

.entry-form-wrapper .styled-select .entry-form-label {
  background: none;
}

.entry-form-wrapper .styled-select .selectric-wrapper {
  width: calc(33.3333% - 13.3333px);
  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #E8E8E8;
  display: inline-block;
}

.entry-form-wrapper .styled-select .selectric-wrapper:last-of-type {
  margin-right: 0;
}

@media all and (max-width: 530px) {
  .entry-form-wrapper .styled-select.entry-form-half {
    min-width: 0;
  }
  .entry-form-wrapper .styled-select .selectric-wrapper {
    width: 100%;
    margin-right: 0;
  }
}

.entry-form-required-note {
  position: relative;
  display: block;
  margin-top: -33px;
}

.entry-form-note {
  position: relative;
  display: block;
  border-top: 1px solid #E8E8E8;
  padding-top: 30px;
  margin-bottom: 120px;
}

.entry-form-note p, .entry-form-note .block-text-content, .entry-form-note .contacts .contact-position, .contacts .entry-form-note .contact-position, .entry-form-note .contacts .contact-info, .contacts .entry-form-note .contact-info, .entry-form-note .search-vcard {
  color: #B0B0B0;
  font-size: 12px;
  line-height: 20px;
}

.entry-form-radio-wrapper input[type='radio'] {
  display: none;
}

input[type=radio]:checked + .entry-form-label-newApprentice:after {
  display: block;
}

.entry-form-radio {
  display: inline-block;
  width: auto;
}

.entry-form-label-newApprentice {
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  display: none;
}

.entry-form-label-newApprentice:before {
  content: '';
  position: absolute;
  left: 5px;
  width: 17px;
  height: 17px;
  border: 1px solid #3CB6CE;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.entry-form-label-newApprentice:after {
  content: '';
  position: absolute;
  display: none;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #3CB6CE;
  left: 9px;
  font-size: 0;
  top: 50%;
  transform: translateY(-50%);
}

.entry-form-profile {
  display: flex;
  align-self: center;
  width: auto;
  -webkit-appearance: checkbox;
  margin: 1px 1px 13px;
}

@media all and (max-width: 530px) {
  .entry-form-profile:nth-of-type(2) {
    margin-top: 20px;
  }
}

.entry-form-label-profile {
  position: relative;
  cursor: pointer;
  display: none;
}

@media all and (max-width: 530px) {
  .entry-form-label-profile:nth-of-type(2) {
    margin-top: 20px;
  }
}

.entry-form-radio-container .entry-form-label-newApprentice {
  display: inline-block;
}

.entry-form-radio-container .entry-form-label-newApprentice:nth-of-type(2) {
  margin-left: 30px;
}

@media all and (max-width: 530px) {
  .entry-form-radio-container .entry-form-label-newApprentice {
    width: 100%;
  }
  .entry-form-radio-container .entry-form-label-newApprentice:nth-of-type(2) {
    margin-left: 0;
    margin-top: 10px;
  }
}

.entry-form-radio-container .entry-form-label-profile {
  width: calc(100% - 28px);
  padding-left: 15px;
  display: flex;
}

.entry-form-radio-container.entry-form-profile-container {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.entry-form-membership {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #3CB6CE;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.entry-form-membership p, .entry-form-membership .block-text-content, .entry-form-membership .contacts .contact-position, .contacts .entry-form-membership .contact-position, .entry-form-membership .contacts .contact-info, .contacts .entry-form-membership .contact-info, .entry-form-membership .search-vcard {
  color: #ffffff;
  display: flex;
  align-self: center;
}

.entry-form-membership .entry-form-membership-btn {
  display: flex;
}

.entry-form-membership .entry-form-membership-btn.btn:hover {
  background-color: #ffffff;
}

.entry-form-finished {
  margin-bottom: 120px;
}

.entry-form-finished .btn {
  margin-top: 20px;
}

.block ol li {
  position: relative;
  margin-bottom: 15px;
}

.block ol li:before {
  content: "";
  position: absolute;
  left: -30px;
  top: 50%;
  margin-top: -5px;
  width: 8px;
  height: 8px;
  text-align: right;
}

.is-lehre-main .block ol li:before {
  background-color: #3CB6CE;
}

:root .is-lehre .block ol li:before {
  background-color: #3CB6CE;
}

:root .is-facebook .block ol li:before {
  background-color: #3C5B9B;
}

:root .is-youtube .block ol li:before {
  background-color: #FE3432;
}

:root .is-twitter .block ol li:before {
  background-color: #2DAAE1;
}

:root .is-instagram .block ol li:before {
  background-color: #6A453B;
}

@media all and (max-width: 667px) {
  .block ol li:before {
    left: -20px;
  }
}
